import React, {useEffect, useState} from 'react';
import {useSearchModalStore} from "../../store/useSearchModalStore";
import iconSearchBlack from './icon_search_black.svg';
import iconButtonSearch from './icon_button_search.svg';

import './SearchBar.css';
import {useLocation} from "react-router-dom";

/**
 * SearchBar - Navigation 에서 사용
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchBar() {
    const { openModal } = useSearchModalStore();
    const [ displayText, setDisplayText ] = useState(undefined);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('/search/')) {
            const query = location.pathname.split('/search/')[1];
            const keyword = query.split('/')[0];
            const decodedQuery = decodeURIComponent(keyword);
            setDisplayText(decodedQuery);
        } else {
            setDisplayText(undefined);
        }
    }, [location]);

    return (
        <>
            {/* PC */}
            <div onClick={openModal} className='SearchBar'>
                <div>
                    <img alt={'search'} src={iconButtonSearch} />
                </div>
                <div className='SearchBar-text'>{displayText || '상품, 브랜드 및 유저 검색'}</div>
            </div>
            {/* Mobile */}
            <div onClick={openModal} className='SearchBar-mobile'>
                {displayText === undefined ?
                    (<div className='SearchBar-mobile-icon'><img alt={'search'} src={iconSearchBlack}/></div>) :
                    (<div className='SearchBar-mobile-input'>
                        <div><img alt={'search'} src={iconButtonSearch}/></div>
                        <div className='SearchBar-text'>{displayText}</div>
                    </div>)
                }
            </div>
        </>
    );
}
