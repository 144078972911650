import React, {useMemo} from 'react';
import {Helmet} from 'react-helmet';
import qr from 'qr-image';

import {useAppStoreUrl, useGooglePlayUrl} from '../../hook/appUrl';
import {downloadUrl, webappURL} from '../../util/url';

import appStoreBadgeIcon from './assets/app_store_badge.svg';
import googlePlayBadgeIcon from './assets/google_play_badge.svg';
import {META_TAG_TITLE} from "../../constants/constants";

import './Download.css';
import PropTypes from 'prop-types';

/**
 * Download App page.
 * @param hostname
 */
export default function Download({hostname}) {
    const appStoreUrl = useAppStoreUrl();
    const googlePlayUrl = useGooglePlayUrl();
    const qrCode = useMemo(
        () => qr.svgObject(
            'https://fruitsfamily.app.link/JU10tJPXV8',
            {
                margin: 0,
                type: 'svg'
            }
        ),
        []
    );

    // build the UI elements
    return (
        <section className="Download container">
            <Helmet>
                <title>앱 다운로드 | {META_TAG_TITLE}</title>
                <meta name="description"
                      content="빈티지 세컨핸드 패션 중고거래는 후루츠. 앱에서 후루츠를 제대로 즐겨보세요." />
                <link rel="canonical" href={webappURL(hostname) + downloadUrl()} />
            </Helmet>

            <div className="Download-content">
                <h2 className="Download-title">
                    앱에서 탐색하고 득템하세요
                </h2>

                <div className="Download-group">
                    <a className="Download-link"
                       href={appStoreUrl}
                       rel="noopener noreferrer"
                       target="_blank">
                        <img className="Download-appstore"
                             alt="App Store에서 다운로드 하기"
                             src={appStoreBadgeIcon} />
                    </a>
                    <a className="Download-link"
                       href={googlePlayUrl}
                       rel="noopener noreferrer"
                       target="_blank">
                        <img className="Download-appstore"
                             alt="다운로드하기 Google Play"
                             src={googlePlayBadgeIcon} />
                    </a>
                </div>

                <svg className="Download-qrcode"
                     viewBox={`0 0 ${qrCode.size} ${qrCode.size}`}
                     shapeRendering="crispEdges">
                    <title>QR코드로 앱 설치</title>
                    <path d={qrCode.path} />
                </svg>
            </div>
        </section>
    );
}

Download.propTypes = {
    hostname: PropTypes.string.isRequired,
}
