import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {brandUrl, webappURL} from '../../util/url';

/**
 * Open Graph metadata component for a brand.
 * @param brand a brand for which to generate metadata
 * @param selectedCategory a category selected for this page
 * @param title meta title
 * @param description meta description
 * @param hostname
 * @return {*}
 * @constructor
 */
export default function BrandOpenGraph({ brand, selectedCategory, title, description, hostname }) {
    return (
        <Helmet>
            <meta property="fb:app_id" content="330702484245822" />
            <meta property="og:site_name" content="FruitsFamily" />
            <meta property="og:type" content="brand.item" />
            <meta property="og:title" content={title}/>
            <meta property='og.description' content={description} />
            <meta property="og:url" content={webappURL(hostname) + brandUrl(brand.name, selectedCategory)} />
            {brand.resizedBigLogo && (
                <meta property="og:image" content={brand.resizedBigLogo} />
            )}
        </Helmet>
    );
}
BrandOpenGraph.propTypes = {
    brand: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        name_kr: PropTypes.string,
        description: PropTypes.string,
        resizedBigLogo: PropTypes.string
    }).isRequired,
    selectedCategory: PropTypes.shape({
        name: PropTypes.string.isRequired
    }),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
};
