import {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {appStoreUrl, extractMarketingParameters, googlePlayUrl} from '../util/appLink';

import {sharedStateHookFactory} from './sharedState';

// shared state between app url react components
const useMarketingParameters = sharedStateHookFactory({});

/**
 * React component for consuming marketing attribution.
 */
export function useMarketingAttributionConsumer() {
    const location = useLocation();
    const [, setLastMarketingParameters] = useMarketingParameters();

    // update the marketing parameters
    useEffect(() => {
        const newMarketingParameters = extractMarketingParameters(location);

        // only update the marketing parameters if new ones are available
        if (Object.keys(newMarketingParameters).length > 0) {
            setLastMarketingParameters(newMarketingParameters);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Factory for creating a react hook returning the app url.
 * @param appUrlBuilder the function which builds the app url
 * @return the generated hook
 */
function appUrlHookFactory(appUrlBuilder) {
    return () => {
        const [lastMarketingParameters] = useMarketingParameters();

        // build and memoize the app url
        const url = useMemo(
            () => appUrlBuilder(lastMarketingParameters),
            [lastMarketingParameters]
        );

        return url;
    };
}

export const useAppStoreUrl = appUrlHookFactory(appStoreUrl);
export const useGooglePlayUrl = appUrlHookFactory(googlePlayUrl);
