import {gql} from 'apollo-boost';
import {useLazyQuery} from "@apollo/react-hooks";

export const GET_CHECK_SNS_CODE = gql`
  query checkSnsCode($type: String!, $code: String!) {
      checkSnsCode(type: $type, code: $code) {
        access_token
        isExistsAccount
      }
  }
`;

/**
 * Social 가입 여부 확인 API
 */
export default function useCheckSnsCode() {
    const [requestCheckSnsCode, {data, error}] = useLazyQuery(GET_CHECK_SNS_CODE, {
        fetchPolicy: 'network-only',
    });

    const executeQuery = (type, code) => {
        requestCheckSnsCode({ variables: { type: type, code: code } });
    };

    return [executeQuery, data, error];
}
