import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import Button from "../../component/button/Button";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import {useNavigate} from "react-router-dom";
import {getGraphQLErrorMsg} from "../../util/util";
import {logAddPaymentInfo, logPurchase} from "../../util/analytics";
import toast from "react-hot-toast";
import Loading from "../../component/loading/Loading";

import './CheckoutButton.css';
import {useDeliveryAddress} from "../../store/useDeliveryAddress";

let paymentModuleInitialized = false;

const PREPARE_ORDER = gql`
  mutation prepareOrder($orderList: [OrderInfo], $deliveryNote: String!) {
    prepareOrder(orderList: $orderList, deliveryNote: $deliveryNote) {
       order_payment_info {
          product_id
          transaction_id
          merchant_uid
          payment_amount
          delivery_address_id
      }
      pg_payment_info
    }
  }
`;

export const RELEASE_PREPARE_ORDER_LOCK = gql`
  mutation ReleasePrepareOrderLock($productId: Int!) {
    releasePrepareOrderLock(productId: $productId)
  }
`;

export default function CheckoutButton({isValid, product, deliveryAddress, receipt}) {
    const navigate = useNavigate();
    const [iamportModalOpened, setIamportModalOpened] = useState(false);
    const [prepareOrder] = useMutation(PREPARE_ORDER);
    const {deliveryNote} = useDeliveryAddress();
    const [releaseLock] = useMutation(RELEASE_PREPARE_ORDER_LOCK);

    async function submitPayment(event) {
        event.preventDefault();

        setIamportModalOpened(true);
        prepareOrder({
            variables: {
                orderList: [
                    {
                        product_id: Number(product.id),
                        delivery_address_id: Number(deliveryAddress.id),
                        payment_amount: receipt.paidAmount,
                    },
                ],
                deliveryNote: deliveryNote
            }
        }).then(response => {
            processPayment(response);
        }).catch(error => {
            setIamportModalOpened(false);
            toast(getGraphQLErrorMsg(error.message));
        });
    }

    //아임포트 결제 실행
    const processPayment = (response) => {
        const requestPayData = response.data.prepareOrder[0];
        // 모바일용 redirect_url을 pg_payment_info 안에 추가
        requestPayData.pg_payment_info = {
            ...requestPayData.pg_payment_info,
            m_redirect_url: `${window.location.protocol}//${window.location.host}/checkout/complete`
        };

        if (!paymentModuleInitialized) {
           paymentModuleInitialized = true;
            window.IMP.init(requestPayData.pg_payment_info.user_code);
        }

        //결제 정보 저장
        sessionStorage.setItem('lastProductInfo', JSON.stringify({
            'product' : product,
            'amount': requestPayData.order_payment_info.payment_amount,
        }));

        window.IMP.request_pay(requestPayData.pg_payment_info,
            response => {
                //PC일 경우에만 호출됨.
                if (response.success) {
                    logPurchase('webapp', product, response.imp_uid, requestPayData.order_payment_info.payment_amount, requestPayData.order_payment_info.payment_amount - product.price);
                    setTimeout(() => {
                        toast('정상적으로 결제가 되었습니다.');
                        setIamportModalOpened(false);
                        navigate('/my/purchased');
                    }, 3000);
                } else {
                    releaseLock({
                        variables: {
                            productId: Number(product.id)
                        }
                    });
                    alert(response.error_msg);
                    setIamportModalOpened(false);
                }

                //결제 완료 후 삭제
                sessionStorage.removeItem('lastProductInfo');
            }
        );
        logAddPaymentInfo('webapp', product, 'CARD');
    }

    return <div>
        <Helmet>
            <script src="https://cdn.iamport.kr/v1/iamport.js"></script>
        </Helmet>
        <Button onClick={submitPayment} disabled={!isValid} text={!iamportModalOpened? '안전결제' : '결제 진행중'} full={true} />
        {iamportModalOpened &&
            <div className='CheckoutButton-loading-container'>
                <Loading />
            </div>
        }
    </div>
}

CheckoutButton.propTypes = {
    isValid: PropTypes.bool.isRequired,
    product: PropTypes.object.isRequired,
    deliveryAddress: PropTypes.object.isRequired,
    receipt: PropTypes.object.isRequired,
}
