import dayjs from 'dayjs';

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_WEEK = 7 * ONE_DAY;

/**
 * Truncate a date to reduce its precision to a given time period (minute, hour, day, etc.).
 * @param date {Date} the date to be truncated
 * @param timePeriod {Number} the time period in milliseconds to truncate the date to
 * @returns {Date} the truncated date
 */
export function truncateDate(date, timePeriod) {
    return new Date(Math.floor(date.getTime() / timePeriod) * timePeriod);
}

/**
 * 날짜 포맷에 사용할 함수
 * @param date
 * @param format optional
 * @returns {string}
 */
export function formatDate(date, format = null) {
    return dayjs(date).format(format ? format : 'YYYY.MM.DD');
}

/**
 * 한국어 날짜 포맷
 * @param date
 * @param format
 * @returns {string}
 */
export function formatKoreanDate(date, format = null) {
    return dayjs(date).format(format ? format : 'YYYY년 M월 DD일');
}
