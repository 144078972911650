import React from 'react';
import PropTypes from 'prop-types';
import {useMounted} from '../../hook/useMounted';

/**
 * useMounted를 사용하여 mount여부를 확인하는 컴포넌트
 * @param children
 * @returns {JSX.Element|null}
 */
export default function MountedWrapper({children}) {
    const isMounted = useMounted();

    if(!isMounted) {
        return null;
    }
    return <>{children}</>
}

MountedWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};