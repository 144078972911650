import React from 'react';
import PropTypes from 'prop-types';

import {hashCode} from '../../util/text';

import './StagingRibbon.css';

/**
 * Component for showing an environment ribbon durring the development, testing and acceptance phases.
 */
export default function StagingRibbon({ hostname }) {
    const environment = hostname.replace(/\.?fruitsfamily\.com/i, '');

    // build the UI elements
    return (environment && environment !== 'www') ?
        (
            <div className="StagingRibbon"
                 style={{
                    filter: `hue-rotate(${hashCode(environment) % 360}deg)`,
                 }}>
                {environment}
            </div>
        ) :
        null;
}
StagingRibbon.propTypes = {
    hostname: PropTypes.string.isRequired
};
