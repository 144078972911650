import React from 'react';
import MyPage from './MyPage';
import PropTypes from 'prop-types';
import MountedWrapper from '../../component/mountedWrapper/MountedWrapper';

export default function MyPageWrapper({hostname, userAgent}) {
    return (
        <MountedWrapper>
            <MyPage hostname={hostname} userAgent={userAgent}/>
        </MountedWrapper>
    );
}

MyPageWrapper.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
