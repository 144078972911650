import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import './NotFound.css';

/**
 * Not Found page.
 */
export default function NotFound() {
    // build the UI elements
    return (
        <section className="NotFound container">
            <Helmet>
                <title>페이지를 찾을 수 없습니다 | 후루츠패밀리</title>
                <meta name="description" content={'후루츠패밀리 | 입력하신 페이지 주소를 다시 한번 확인해 주세요.'} />
                <meta httpEquiv="Status" content="404 Not Found" />
                <meta name="robots" content="noindex" />
            </Helmet>

            <h2 className="NotFound-title">
                페이지를 찾을 수 없습니다
            </h2>

            <p className="NotFound-description">
                원하시는 결과를 찾을 수 없습니다.<br />
                <Link className="NotFound-link" to="/">메인으로 돌아가기</Link>
            </p>
        </section>
    );
}
