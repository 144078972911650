import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

import Empty from '../../../component/empty/Empty';
import Error from '../../../component/error/Error';
import Loading from '../../../component/loading/Loading';
import ProductPreview, {PRODUCT_FRAGMENT} from '../../../component/productPreview/ProductPreview';

import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from "../../product/Product";

import emptyLikeIcon from './assets/empty_like.svg';
import './Like.css';

// GraphQL queries
const SEE_USER_LIKES = gql`
    query SeeUserLikes($userId: Int!) {
        seeUserLikes(user_id: $userId) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Seller like tab component.
 */
export default function Like({ seller }) {
    // fetch the liked products list
    const { loading, error, data } = useQuery(
        SEE_USER_LIKES,
        {
            variables: {
                userId: parseInt(seller.user.id, 10)
            },
            fetchPolicy: 'cache-and-network' // force cache refresh to show new likes
        }
    );

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    // build the UI elements
    const renderProduct = product => (
        <li className="Like-item col mb-2 mb-sm-4" key={product.id}>
            <div className="Like-1by1">
                <div className="Like-1by1-content">
                    <ProductPreview product={product} />
                </div>
            </div>
        </li>
    );
    const listItems = data.seeUserLikes.length ?
        data.seeUserLikes.map(renderProduct) :
        (
            <li className="Like-empty">
                <Empty>
                    <img className="Like-empty-icon"
                         alt=""
                         src={emptyLikeIcon} />
                    찜한 상품이 없습니다
                </Empty>
            </li>
        );

    return (
        <section className="Like">
            <ul className="Like-list list-unstyled row row-cols-2 row-cols-md-3 row-cols-xl-4">
                {listItems}
            </ul>
        </section>
    );
}
Like.propTypes = {
    seller: PropTypes.shape({
        user: PropTypes.shape({
            id:  PropTypes.string.isRequired
        })
    }).isRequired
};

