import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route, Routes, useLocation} from 'react-router-dom';

import {useMarketingAttributionConsumer} from '../../hook/appUrl';
import Brand from '../../page/brand/Brand';
import Collection from '../../page/collection/Collection';
import Discover from '../../page/discover/Discover';
import Download from '../../page/download/Download';
import Home from '../../page/home/Home';
import NotFound from '../../page/notFound/NotFound';
import Product from '../../page/product/Product';
import Seller from '../../page/seller/Seller';
import Search from "../../page/search/Search";
import Login from "../../page/auth/login/Login.jsx";
import SignUp from "../../page/auth/signUp/SignUp";
import SignUpSns from "../../page/auth/signUp/SignUpSns";
import SocialAuthComplete from "../../page/auth/socialAuthComplete/SocialAuthComplete";
import Settings from '../../page/settings/Settings';
import MyPageWrapper from '../../page/myPage/MyPageWrapper';
import PurchasedWrapper from '../../page/purchased/PurchasedWrapper';
import OrderWrapper from '../../page/order/OrderWrapper';
import RegisterCheckoutShipping from "../../page/checkout/shipping/RegisterCheckoutShipping";
import CheckoutComplete from "../../page/checkout/complete/CheckoutComplete";
import CheckoutWrapper from "../../page/checkout/CheckoutWrapper";
import FriendInvite from "../../page/friendInvite/FriendInvite";
import ErrorPage from "../../page/errorPage/ErrorPage";


/**
 * Component with contains all the global routes.
 * @param hostname the webapp hostname
 * @param location a location object to be used for matching children elements instead of the current history location
 * @param userAgent the user agent
 * @return {*}
 * @constructor
 */
export default function AppRoutes({ hostname, userAgent }) {
    const location = useLocation();

    useEffect(() => {
        // scroll to top on root page change
        window.scrollTo(0, 0);
    }, [location]);

    // consume marketing attribution from location parameters
    useMarketingAttributionConsumer();

    // build the UI elements
    return (
        <Routes>
            <Route path="/" element={<Home hostname={hostname} userAgent={userAgent} />} />
            <Route path="/brand/:brandName/:categoryName?" element={<Brand hostname={hostname}/>} />
            <Route path="/collection/:collectionId/*" element={<Collection hostname={hostname} userAgent={userAgent} />} />
            <Route path="/discover/:discoverTab?/:categoryName?" element={<Discover hostname={hostname} userAgent={userAgent} />} />
            <Route path="/download" element={<Download hostname={hostname}/>} />
            <Route path="/product/:productId/*" element={<Product hostname={hostname} userAgent={userAgent} />} />
            <Route path="/seller/:sellerId/:encodedTitle?/:sellerTab?" element={<Seller hostname={hostname} userAgent={userAgent} />} />
            <Route path="/search/:keyword/:categoryName?" element={<Search hostname={hostname}/>} />
            <Route path="/order/purchased" element={<Download hostname={hostname}/>} />
            <Route path="/order/selling" element={<Download hostname={hostname}/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/signUpSns" element={<SignUpSns />} />
            <Route path="/auth/kakao/complete" element={<SocialAuthComplete socialType={'KAKAO'} />} />
            <Route path="/auth/apple/complete" element={<SocialAuthComplete socialType={'APPLE'}/>} />
            <Route path="/auth/facebook/complete" element={<SocialAuthComplete socialType={'FACEBOOK'}/>} />
            <Route path="/my/:sellerTab?" element={<MyPageWrapper hostname={hostname} userAgent={userAgent} />} />
            <Route path="/my/purchased" element={<PurchasedWrapper hostname={hostname} userAgent={userAgent} />} />
            <Route path="/my/purchased/:orderId" element={<OrderWrapper hostname={hostname} userAgent={userAgent} />} />
            <Route path="/my/settings" element={<Settings hostname={hostname} userAgent={userAgent} />} />
            <Route path="/checkout/:productId" element={<CheckoutWrapper hostname={hostname}/>}/>
            <Route path="/checkout/shipping/:productId" element={<RegisterCheckoutShipping />}/>
            <Route path="/checkout/complete" element={<CheckoutComplete />}/>
            <Route path="/event/join/:code" element={<FriendInvite hostname={hostname} />}/>
            <Route path="/temporary-wrong" element={<ErrorPage />}/>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
AppRoutes.propTypes = {
    hostname: PropTypes.string.isRequired,
    location: PropTypes.object,
    userAgent: PropTypes.string.isRequired
};
