import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

/**
 * Twitter card metadata component for a product.
 * @param product a product for which to generate metadata
 * @param title meta title
 * @return {*}
 * @constructor
 */
export default function ProductTwitterCard({ product, title }) {
    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@fruitsfamilyco" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={product.description} />
            <meta name="twitter:image" content={product.resizedBigImages[0]} />
            <meta name="twitter:image:alt" content={title} />
        </Helmet>
    );
}
ProductTwitterCard.propTypes = {
    product: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        resizedBigImages: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    title: PropTypes.string.isRequired
};
