import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {homeUrl, webappURL} from '../../util/url';
import {META_TAG_TITLE} from "../../constants/constants";

/**
 * Open Graph metadata component for the home page.
 * @param hostname the webapp hostname
 * @return {*}
 * @constructor
 */
export default function HomeOpenGraph({ hostname }) {
    return (
        <Helmet>
            {/* Cf schema at https://ogp.me/#type_website */}
            <meta property="fb:app_id" content="330702484245822" />
            <meta property="og:site_name" content="FruitsFamily" />
            <meta property="og:type" content="website " />
            <meta property="og:title" content={META_TAG_TITLE} />
            <meta property="og:description" content="유니크한 빈티지, 세컨핸드부터 구하기 힘든 하이엔드 디자이너 브랜드까지 후루츠에서 한번에." />
            <meta property="og:url" content={webappURL(hostname) + homeUrl()} />
            <meta property="og:image" content={`https://${hostname}/logo512.png`} />
        </Helmet>
    );
}
HomeOpenGraph.propTypes = {
    hostname: PropTypes.string.isRequired
};
