import {Accordion} from "../../component/accordion/Accordion";
import React from "react";

import './Product.css';

/**
 * 상품 정책 관련 컴포넌트
 * @returns {Element}
 * @constructor
 */
export default function ProductPolicy() {
    return <div className='Product-policy-info'>
        <section>
            <Accordion title={<h4 className="title">배송정보</h4>} content=
                {
                    <div className='content'>
                        <li>상품은 판매자 측에서 직접 배송하며 평균적으로 2일 이내 배송이 시작됩니다.</li>
                        <li>배송 상태는 후루츠 앱에서 확인 가능하고, 그 외 문의는 판매자에게 연락해 주시기 바랍니다.</li>
                        <li>판매자와 연락이 되지 않는 경우, 후루츠 고객센터로 문의해 주시면 확인 도와드리겠습니다.</li>
                    </div>
                }
            />
        </section>
        <section>
            <Accordion title={<h4 className="title">반품 및 환불 정책</h4>} content=
                {
                    <div className='content'>
                        <p>판매자가 통신판매업자인 경우, 구매자의 반품 요청 시 협의를 진행해 주셔야 하니 상호 간 원만한 협의를 부탁드립니다.</p>
                        <p>중고거래 특성상, 개인 간 개인 거래는 반품이 원칙적으로 어렵습니다. 단, 후루츠 안전결제를 이용하시면 아래 경우에는 반품 및 환불
                            진행을
                            도와드립니다.</p>
                        <p>
                            <li>받은 상품이 설명과 다른 경우</li>
                            <li>구매한 상품이 배송되지 않은 경우</li>
                        </p>
                        <p>외부(계좌) 거래 시, 후루츠 고객 지원이 불가능합니다.</p>
                    </div>
                }
            />
        </section>
    </div>
}
