import React from 'react';
import fruitsfamilyLogo from "../appTopBanner/assets/rounded_logo.svg";
import iconStar from "./assets/ic_yellow_star.svg";
import popupClose from "./assets/popup_close.svg";
import PropTypes from "prop-types";
import {isAndroid, isIOS} from "../../util/navigator";
import {useAppStoreUrl, useGooglePlayUrl} from "../../hook/appUrl";
import {useAppInstallModal} from "../../store/useAppInstallModal";

/**
 * 앱 설치 유도 팝업 모바일 버전
 * @param text
 * @returns {Element}
 * @constructor
 */
export default function AppInstallModalMobile({ text }) {
    const { closeModal } = useAppInstallModal();
    const userAgent = window.navigator.userAgent;
    const appStoreUrl = useAppStoreUrl();
    const googlePlayUrl = useGooglePlayUrl();

    const handleOnClick = () => {
        if (isIOS(userAgent)) {
            window.location.href = appStoreUrl;
        } else if (isAndroid(userAgent)) {
            window.location.href = googlePlayUrl;
        }
    }

    return <div className='AppInstallModal-Mobile-content'>
        <div onClick={closeModal} className="PopupClose">
            <img src={popupClose} alt='popup-close' className="PopupCloseImage" />
        </div>
        <img className="Logo" alt="FruitsFamily™ Logo" src={fruitsfamilyLogo}/>
        <div className="Appstore">
            <div className="Text">App Store 평점</div>
            <div className="StoreRating">
                <div>4.9</div>
                <div className="StoreRatingImages">
                    <div>
                        <img src={iconStar} alt="yellow-star" className="StoreRatingStar" />
                        <img src={iconStar} alt="yellow-star" className="StoreRatingStar" />
                        <img src={iconStar} alt="yellow-star" className="StoreRatingStar" />
                        <img src={iconStar} alt="yellow-star" className="StoreRatingStar" />
                        <img src={iconStar} alt="yellow-star" className="StoreRatingStar" />
                    </div>
                </div>
            </div>
        </div>
        <div className='Title'>{text || '앱에서 후루츠를\n제대로 즐겨보세요!'}</div>
        <button className="AppOpenButton" onClick={handleOnClick}>앱 열기</button>
    </div>
}

AppInstallModalMobile.propTypes = {
    text: PropTypes.string
}
