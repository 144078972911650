import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {useQuery} from "@apollo/react-hooks";
import {gql} from 'apollo-boost';

import Carousel from '../../component/bootstrap/carousel/Carousel';
import {PRODUCT_FRAGMENT} from '../../component/productPreview/ProductPreview';
import ProductsList from '../../component/productsList/ProductsList';
import {ONE_DAY, ONE_MINUTE, truncateDate} from "../../util/date";
import {organizationSchemaOrg, SCHEMA_CONTEXT} from '../../util/schemaOrg';
import {homeUrl, webappURL} from '../../util/url';

import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from '../product/Product';

import DiscoveryCollectionsList from './discoveryCollectionsList/DiscoveryCollectionsList';
import HorizontalProductsList from './horizontalProductsList/HorizontalProductsList';
import PopularBrands from './popularBrands/PopularBrands';
import HomeOpenGraph from './HomeOpenGraph';
import HomeTwitterCard from './HomeTwitterCard';

import './Home.css';

const PLACEHOLDER_DATA = [{}];

// GraphQL queries
export const GET_HOME_BANNERS = gql`
    {
        getHomeBannersCached(device: WEB) {
            background_image
            image
            link
            description
        }
    }
`;
const EXPLORE_PRODUCT = gql`
    query GetCollectionProducts($offset: Int, $limit: Int) {
        getCollectionProducts(collection_id: 1, offset: $offset, limit: $limit) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
            price
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Home page component.
 * @param hostname the webapp hostname
 * @param userAgent the user agent
 * @return {*}
 * @constructor
 */
export default function Home({ hostname, userAgent }) {
    // home banner
    const {data} = useQuery(GET_HOME_BANNERS);

    // the date is memoized to keep the same parameters when loading more result pages, and is truncated to a fixed
    // precision so several users or close-by executions can reuse the same results from the server-side cache
    const hiddenGemsCreatedFrom = useMemo(() => new Date(truncateDate(new Date(), ONE_MINUTE) - 8 * ONE_DAY), []);
    const hiddenGemsCreatedUntil = useMemo(() => new Date(truncateDate(new Date(), ONE_MINUTE) - 7 * ONE_DAY), []);

    // build the UI elements
    return (
        <div className="Home">
            <Helmet>
                <link rel="canonical" href={webappURL(hostname) + homeUrl()} />
                <link rel="alternate" type="application/atom+xml" href="/feed.atom" />
                <script type="application/ld+json">{JSON.stringify(organizationSchemaOrg(SCHEMA_CONTEXT))}</script>
            </Helmet>
            <HomeOpenGraph hostname={hostname} />
            <HomeTwitterCard hostname={hostname} />
            {/* hidden h1 */}
            <h1 className="Home-h1-tag">빈티지 세컨핸드 패션 플랫폼</h1>

            {/* banner */}
            <Carousel hideControls={true}>
                {(data ? data.getHomeBannersCached : PLACEHOLDER_DATA).map((banner, index) => (
                    <div className="Home-banner"
                         key={index}
                         style={{backgroundImage: `url(${banner.background_image})`}}>
                        <a className="Home-banner-container container"
                           href={banner.link}>
                            <img className="Home-banner-image"
                                 alt={banner.description}
                                 src={banner.image} />
                        </a>
                    </div>
                ))}
            </Carousel>

            <div className="Home-container container mt-4 mt-md-5">
                {/* latest products */}
                <h4 className="Home-section-title font-nhaasgrotesk">
                    Popular Listings
                </h4>
                <h5 className="Home-section-caption">
                    인기 상품
                </h5>
                <div className="Home-section-content">
                    <HorizontalProductsList userAgent={userAgent}
                                            rowClassName="row-cols-3 row-cols-sm-4 row-cols-lg-5 row-cols-xl-6"
                                            variables={{
                                              filter: {
                                                  price_min: 100001,
                                                  show_only: 'selling',
                                                  minBrandScore: 0
                                              },
                                              sort: 'POPULAR'
                                          }} />
                </div>
                {/* brands */}
                <h5 className="Home-section-caption">
                    인기 브랜드
                </h5>
                <div className="Home-section-content">
                    <PopularBrands />
                </div>
                {/* deals of the week */}
                <h4 className="Home-section-title font-nhaasgrotesk">
                    Deals of the Week
                </h4>
                <h5 className="Home-section-caption">
                    득템 아이템
                </h5>
                <div className="Home-section-content">
                    <HorizontalProductsList userAgent={userAgent}
                                            rowClassName="row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5"
                                            variables={{
                                                filter: {
                                                    price_max: 100000,
                                                    show_only: "selling",
                                                    minBrandScore: 0
                                                },
                                                sort: "NEW"
                                            }} />
                </div>
                {/* hidden gems */}
                <h4 className="Home-section-title font-nhaasgrotesk">
                    Hidden Gems
                </h4>
                <h5 className="Home-section-caption">
                    숨겨진 보석
                </h5>
                <div className="Home-section-content">
                    <HorizontalProductsList userAgent={userAgent}
                                            rowClassName="row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5"
                                            variables={{
                                                filter: {
                                                    created_from: hiddenGemsCreatedFrom,
                                                    created_until: hiddenGemsCreatedUntil,
                                                    show_only: 'selling',
                                                    minBrandScore: 0
                                                },
                                                sort: 'HIGH_VIEW'
                                            }} />
                </div>
                {/* collections */}
                <div className="Home-section-caption">
                    SPOTLIGHT
                </div>
                <div className="Home-section-content">
                    <DiscoveryCollectionsList userAgent={userAgent} />
                </div>
                {/* we love */}
                <h4 className="Home-section-title font-nhaasgrotesk">
                    We Love
                </h4>
                <h5 className="Home-section-caption">
                    후루츠 픽
                </h5>
                <div className="Home-picks">
                    <ProductsList itemsProperty="getCollectionProducts"
                                  query={EXPLORE_PRODUCT} />
                </div>
            </div>
        </div>
    );
}
Home.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
