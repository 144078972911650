import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {gql} from "apollo-boost";

import FormInput from "../../../component/rhf/formInput/FormInput";
import FormSelect from "../../../component/rhf/formSelect/FormSelect";
import MobileMaxWidthWrapper from "../../../component/mobileMaxWidthWrapper/MobileMaxWidthWrapper";
import Button from "../../../component/button/Button";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {SEE_PRODUCT} from "../Checkout";
import {logAddShippingInfo} from "../../../util/analytics";
import {NUMBER_VALIDATION} from "../../../constants/validation_constants";
import {useDeliveryAddress} from "../../../store/useDeliveryAddress";

import './RegisterCheckoutShipping.css';

export const ADD_DELIVERY_ADDRESS = gql`
  mutation addDeliveryAddress(
    $address1: String!
    $address2: String
    $postalCode: String!
    $recipientName: String
    $recipientPhone: String
  ) {
    addDeliveryAddress(address1: $address1, address2: $address2, postal_code: $postalCode, recipient_name: $recipientName, recipient_phone: $recipientPhone)
  }
`
/**
 * 배송지 등록 페이지
 * @returns {JSX.Element}
 * @constructor
 */
export default function RegisterCheckoutShipping() {
    const navigate = useNavigate();
    const [addDeliveryAddress, { loading }] = useMutation(ADD_DELIVERY_ADDRESS, {
        onCompleted: (data) => {
            if (data.addDeliveryAddress === true) {
                if (productData) {
                    logAddShippingInfo('webapp', productData.seeProduct);
                }
                navigate(-1);
            } else {
                alert('배송지 등록에 실패했습니다.');
            }
        },
        onError: () => {
            alert('배송지 등록 중 오류가 발생했습니다.');
        }
    });
    const {deliveryAddress, deliveryNote, setDeliveryNote} = useDeliveryAddress();

    const methods = useForm({
        mode :"onChange",
        defaultValues: {
            deliveryNote,
            ...deliveryAddress,
        }
    }, );
    const { handleSubmit, formState: { isValid }, setValue } = methods;

    // fetch product details
    const {productId: encodedProductId} = useParams();
    const productId = parseInt(encodedProductId, 36);
    const {data: productData} = useQuery(
        SEE_PRODUCT,
        {
            fetchPolicy: 'cache-and-network',
            returnPartialData: false,
            variables: {
                productId
            }
        }
    );

    const onSubmit = async (formData) => {
        if (loading) return;

        setDeliveryNote(formData.deliveryNote);

        await addDeliveryAddress({
            variables: {
                address1: formData.address1,
                address2: formData.address2,
                postalCode: formData.postal_code,
                recipientName: formData.recipient_name,
                recipientPhone: formData.recipient_phone,
            }
        });
    };


    function requestAddress() {
        new window.daum.Postcode({
            oncomplete: function(data) {
                setValue('postal_code', data.zonecode, { shouldValidate: true });
                setValue('address1', data.roadAddress, { shouldValidate: true });
            }
        }).open();
    }

    return <MobileMaxWidthWrapper className={'max-width-400'}>
        <Helmet>
            <script src="https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"></script>
        </Helmet>
        <div className='RegisterCheckoutShipping'>
            <h1 className='page-title'>배송지</h1>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='input'>
                        <FormInput label="이름" name="recipient_name" placeholder={'수령인의 이름'}
                                   rules={{ required: true }} />
                    </div>
                    <div className='input'>
                        <FormInput label="휴대폰 번호" type="phone" name="recipient_phone"
                                   placeholder={'-제외하고 입력'}
                                   rules={{ required: true, pattern: NUMBER_VALIDATION  }} />
                    </div>
                    <div className='input'>
                        <FormInput label="우편번호"  name="postal_code"
                                   placeholder={'우편번호 검색'}
                                   onClick={requestAddress}
                                   readOnly={true}
                                   rules={{ required: true }} />
                    </div>
                    <div className='input'>
                        <FormInput label="주소"  name="address1"
                                   placeholder={'우편번호 검색시 자동 입력'}
                                   onClick={requestAddress}
                                   rules={{ required: true }} />
                    </div>
                    <div className='input'>
                        <FormInput label="상세 주소" name="address2"
                                   placeholder={'건물, 아파트, 동/호수'}
                                   rules={{ required: true }} />
                    </div>

                    <div className='input'>
                        <FormSelect name="deliveryNote" rules={{ required: true }} options={
                            [
                                {value: '배송 전에 미리 연락 바랍니다.', label: '배송 전에 미리 연락 바랍니다.'},
                                {value: '문 앞에 놔주세요.', label: '문 앞에 놔주세요.'},
                                {value: '경비실에 맡겨주세요.', label: '경비실에 맡겨주세요.'},
                                {value: '택배함에 놔주세요.', label: '택배함에 놔주세요.'},
                            ]
                        } label={'요청사항'}  placeholder={'요청 사항을 선택해주세요.'}/>
                    </div>

                    <div className='button'>
                        <Button onClick={handleSubmit(onSubmit)} disabled={!isValid} full={true} text={'저장'} />
                    </div>
                </form>
            </FormProvider>
        </div>
    </MobileMaxWidthWrapper>
}
