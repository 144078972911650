import React, {useState} from 'react';
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";

import './FormSelect.css';

/**
 * React Hook Form을 사용하는 Select 컴포넌트
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormSelect({ label, name, placeholder, options, rules, ...rest}) {
    const { register, setValue } = useFormContext();
    const [valueSelected, setValueSelected] = useState('');

    const handleChange = (e) => {
        setValueSelected(e.target.value);
        setValue(name, e.target.value, { shouldValidate: true })
    }

    return <div className='form-select-container'>
        <label>{label}</label>
        <div className='form-select-box'>
            <select className={`${valueSelected !== "" ? "selected" : ""}`} name={name} {...register(name, rules)} {...rest} defaultValue={''} onChange={handleChange}>
                <option value="" disabled>{placeholder}</option>
                {options.map((option, index) => {
                    return <option key={index} value={option.value}>{option.label}</option>
                })}
            </select>
        </div>
    </div>
}

FormSelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    rules: PropTypes.object,
    required: PropTypes.bool
};
