import React, {useEffect, useState} from "react";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import _ from "lodash";
import {useAuthStore} from "../../store/useAuthStore";
import {getAccessKey} from "../../util/secureAuthStorage";

export const GET_MY_INFO = gql`
  query getMyAccountInfos {
    seeMe {
      id
      login_type
      username
      nickname
      access_token
      nickname
      following_count
      follower_count
      bio
      resizedSmallImage
      resizedBigImage
      email
      phone
      gender
      createdAt
      updatedAt
      seller {
        id
        income
        rating
        sold_count
        fee
        seller_account {
          bank_name
          account_holder_name
          bank_account
        }
      }
    }
  }
`;

export default function AutoLogin() {
    const {setUser, login} = useAuthStore();
    const [hasAccessToken, setHasAccessToken] = useState('');
    const {
        data: { seeMe: myInfoData = {} } = {},
    } = useQuery(GET_MY_INFO, {skip: !hasAccessToken});

    useEffect(() => {
        if (!_.isEmpty(myInfoData)) {
            setUser(myInfoData);
            login(myInfoData);
        }
    }, [myInfoData]);

    useEffect(() => {
        const token = getAccessKey();
        if (token !== null) {
            setHasAccessToken(token);
        }
    }, []);

    return <></>
}
