import React from 'react';
import PropTypes from 'prop-types';

import './SoldRibbon.css';

/**
 * Component for the sold ribbon.
 */
export default function SoldRibbon({ product }) {
    // build the UI elements
    return product.status === 'sold' ?
        (
            <div className="SoldRibbon">
                <svg className="SoldRibbon-text font-nhaasgrotesk"
                     viewBox="0 0 3 1" xmlns="http://www.w3.org/2000/svg">
                    <text x="50%" y="50%"
                          dominantBaseline="central"
                          textAnchor="middle"
                          fill="currentColor"
                          fontSize="1">Sold</text>
                </svg>
            </div>
        ) :
        null;
}
SoldRibbon.propTypes = {
    product: PropTypes.shape({
        status: PropTypes.oneOf(['selling', 'sold']).isRequired
    }).isRequired
};
