import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';

import {discoverUrl, homeUrl, loginUrl, myPageUrl, signUpUrl} from '../../util/url';
import fruitsfamilyLogo from './assets/logo.svg';
import AppTopBanner from "../appTopBanner/AppTopBanner";
import SearchBar from "../searchBar/SearchBar";
import {useAuthStore} from "../../store/useAuthStore";
import {removeAfterLoginPath} from "../../util/util";

import './Navigation.css';

export default function Navigation ({ userAgent, hostname }) {
    const navigate = useNavigate();
    const location = useLocation();
    const {isLogin, redirectTo} = useAuthStore();

    useEffect(() => {
        if (redirectTo) {
            navigate(redirectTo);
        }
    }, [redirectTo]);

    //login 페이지 일때를 제외하고는 afterLoginPath 를 제거
    useEffect(() => {
       if (location.pathname !== '/login') {
           removeAfterLoginPath();
       }
    }, [location]);



    return (
        <header className="Navigation sticky-top">
            <AppTopBanner hostname={hostname} userAgent={userAgent} />
            <nav className="Navigation-navbar navbar navbar-expand-md navbar-light bg-white">
                <div className="container">
                    <NavLink className="Navigation-brand navbar-brand"
                             to={homeUrl()}>
                        <img className="Navigation-logo"
                             alt="FruitsFamily™"
                             src={fruitsfamilyLogo} />
                    </NavLink>
                    <SearchBar />
                    <button type="button"
                            className="Navigation-toggler navbar-toggler"
                            data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="Navigation-toggler-icon navbar-toggler-icon"></span>
                    </button>
                    <div className="Navigation-collapse navbar-collapse collapse Navigation-container" id="navbarNav">
                        <ul className="Navigation-nav navbar-nav ml-auto">
                            <li className="Navigation-item nav-item"
                                data-toggle="collapse" data-target="#navbarNav.show">
                                <NavLink className="Navigation-link nav-link"
                                         to={homeUrl()}>
                                    홈
                                </NavLink>
                            </li>
                            <li className="Navigation-item nav-item"
                                data-toggle="collapse" data-target="#navbarNav.show">
                                <NavLink className="Navigation-link nav-link"
                                         to={discoverUrl()}>
                                    마켓
                                </NavLink>
                            </li>
                            <li className="Navigation-item nav-item"
                                data-toggle="collapse" data-target="#navbarNav.show">
                                <a className="Navigation-link nav-link"
                                   href="https://support.fruitsfamily.com/sell"
                                   rel="noopener noreferrer"
                                   target="_blank">
                                    판매
                                </a>
                            </li>
                            <li className="Navigation-item nav-item"
                                data-toggle="collapse" data-target="#navbarNav.show">
                                <a className="Navigation-link nav-link"
                                   href="https://www.instagram.com/fruitsfamily.co/"
                                   rel="noopener noreferrer"
                                   target="_blank">
                                    인스타그램
                                </a>
                            </li>
                            {isLogin ? <>
                                <li className="Navigation-item nav-item"
                                    data-toggle="collapse" data-target="#navbarNav.show">
                                    <NavLink className="Navigation-link nav-link"
                                             to={myPageUrl()}>
                                        마이페이지
                                    </NavLink>
                                </li>
                            </> : <>
                                <li className="Navigation-item nav-item"
                                    data-toggle="collapse" data-target="#navbarNav.show">
                                    <NavLink className="Navigation-link nav-link"
                                             to={signUpUrl()}>
                                        회원 가입
                                    </NavLink>
                                </li>
                                <li className="Navigation-item nav-item"
                                    data-toggle="collapse" data-target="#navbarNav.show">
                                    <NavLink className="Navigation-link nav-link"
                                             to={loginUrl()}>
                                        로그인
                                    </NavLink>
                                </li>
                            </>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}
Navigation.propTypes = {
    userAgent: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired
};
