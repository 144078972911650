import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {gql} from 'apollo-boost';

import {useAuthStore} from '../../store/useAuthStore';
import {numberCompactFormat} from '../../util/locale';
import {myPageUrl, sellerUrl} from '../../util/url';
import UserPreview, {USER_FRAGMENT} from '../userPreview/UserPreview';

import './SellerPreview.css';
import {useAppInstallModal} from "../../store/useAppInstallModal";
import {openAppAndAction} from "../../util/util";

// GraphQL queries
export const SELLER_FRAGMENT = gql`
    fragment SellerFragment on SellerNotMe {
        id
        user {
            ...UserFragment
            username
            nickname
            follower_count
        }
    }
    ${USER_FRAGMENT}
`;

export default function SellerPreview({ seller }) {
    const { openModal } = useAppInstallModal();
    const store = useAuthStore();

    const handleOnClickPopup = () => {
        openAppAndAction(() => openModal(null), null, 1000);
    };

    // build the UI elements
    return (
        <div className="SellerPreview">
            <Link className='SellerPreview-link'
                  to={store.user && store.user.id === seller.id ? myPageUrl() : sellerUrl(seller)}>
                <div className="SellerPreview-profile">
                    <UserPreview user={seller.user} />
                </div>
                <div className="SellerPreview-description">
                    <h5 className="SellerPreview-nickname font-nhaasgrotesk">
                        {seller.user.nickname}
                    </h5>
                    <div className="SellerPreview-follower">
                        {
                            numberCompactFormat(

                            typeof seller.user.follower_count !== 'undefined' ?
                                seller.user.follower_count :
                                seller.follower_count
                            )
                        } 팔로워
                    </div>
                </div>
            </Link>
            <a className="SellerPreview-follow btn btn-dark"
               onClick={handleOnClickPopup}>
                팔로우
            </a>
        </div>
    );
}
SellerPreview.propTypes = {
    seller: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        follower_count: PropTypes.number, // TODO follower_count is not consistently on the same entity in the api
        user: PropTypes.shape({
            username: PropTypes.string.isRequired,
            nickname: PropTypes.string.isRequired,
            resizedSmallImage: PropTypes.string,
            follower_count: PropTypes.number // TODO follower_count is not consistently on the same entity in the api
        }).isRequired
    }).isRequired,
};
