import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {META_TAG_TITLE} from "../../constants/constants";

/**
 * Twitter card metadata component for the home page.
 * @param hostname the webapp hostname
 * @return {*}
 * @constructor
 */
export default function HomeTwitterCard({ hostname }) {
    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@fruitsfamilyco"/>
            <meta name="twitter:title" content={META_TAG_TITLE}/>
            <meta name="twitter:description" content="유니크한 빈티지, 세컨핸드부터 구하기 힘든 하이엔드 디자이너 브랜드까지 후루츠에서 한번에."/>
            <meta name="twitter:image" content={`https://${hostname}/logo512.png`}/>
            <meta name="twitter:image:alt" content={`${META_TAG_TITLE} 로고`}/>
        </Helmet>
    );
}
HomeTwitterCard.propTypes = {
    hostname: PropTypes.string.isRequired
};
