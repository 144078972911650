import React from 'react';
import {Link} from "react-router-dom";
import './AppPcBanner.css';
import './AppOpenButton.css';
import fruitsfamilyLogo from "./assets/border_gray_logo.svg";

export default function AppPcBanner() {
    return (
        <div className='d-flex justify-content-center align-items-center Pc-banner-container'>
            <Link className={'Pc-banner-container-link text-muted'} to={'/download'}>
                <div className='Pc-banner'>
                    <img className='Icon' alt="FruitsFamily™" src={fruitsfamilyLogo} />
                    <div className='Text'>앱에서 후루츠를 제대로 즐겨보세요!</div>
                    <div className={'App-open-button-pc'}>
                        <div>앱 다운로드</div>
                    </div>
                </div>
            </Link>
        </div>
    );
}