import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from "react-router-dom";
import {isMobile} from "../../util/navigator";
import AppMobileBanner from "./AppMobileBanner";
import AppPcBanner from "./AppPcBanner";

export default function AppTopBanner({hostname, userAgent}) {
    const [isAppBannerVisible, setAppBannerVisible] = useState(false);
    const location = useLocation();
    const mobile = isMobile(userAgent);

    useEffect(() => {
        if (location.pathname !== '/download') {
            setAppBannerVisible(true);
        } else {
            setAppBannerVisible(false);
        }
    }, [location.pathname]);

    return <>
        {isAppBannerVisible ?
            mobile ? <AppMobileBanner userAgent={userAgent} hostname={hostname} /> : <AppPcBanner /> : <></>
        }
    </>
}

AppTopBanner.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired,
};

