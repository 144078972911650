

// This function is used to get the error message from the GraphQL error
import {isAndroid, isIOS} from "./navigator";

export function getGraphQLErrorMsg(errMsg) {
    return errMsg.replace(/^GraphQL error:\s*/i, "")
        .replace(/^Error:\s*/i, "")
        .replace(/^Validation error:\s*/i, "");
}

export function convertCondition(condition) {
    const conditionObj = {
        'NEW': '새상품',
        'GOOD_CONDITION': '아주 좋은 상태',
        'LIGHTLY_WORN': '약간의 사용감',
        'WORN': '사용감 있음',
    };
    return  conditionObj[condition];
}

//비로그인 상태에서 로그인 후 이동할 경로를 저장
export function saveAfterLoginPath(path) {
    sessionStorage.setItem('afterLoginPath', path);
}

//로그인 후 이동할 경로를 가져옴
export function redirectAfterLogin() {
    const afterLoginPath = sessionStorage.getItem('afterLoginPath');
    if (afterLoginPath) {
        sessionStorage.removeItem('afterLoginPath');
    }
    return afterLoginPath;
}

//로그인 후 이동할 경로 삭제
export function removeAfterLoginPath() {
    sessionStorage.removeItem('afterLoginPath');
}

export function convertGender(gender) {
    const genderObj = {
        'MEN': '남자',
        'WOMEN': '여자',
        'UNISEX': '유니섹스',
    };
    return  genderObj[gender];
}

//앱을 열어보고 n초 후에 액션을 실행
export function openAppAndAction( action, specialUrl, delay = 3000) {
    //앱을 딥링크 형태로 열어본다.
    const userAgent = window.navigator.userAgent;
    if (isIOS(userAgent) || isAndroid(userAgent)) {
        const url = specialUrl ? specialUrl : window.location.pathname;
        window.location.href = `fruitsfamily:/${url}`;

        setTimeout(() => {
            action();
        }, delay);
    } else {
        action();
    }
}

//32비트 정수 범위 내에 있는지 확인
export function isWithinInt32Range(value) {
    const MIN_INT32 = -2147483648;
    const MAX_INT32 = 2147483647;
    return Number.isInteger(value) && value >= MIN_INT32 && value <= MAX_INT32;
}

//hostname 별 meta tag robots value
export function getRobotsMetaTag(hostname) {
    switch (hostname) {
        case 'localhost':
        case 'staging.fruitsfamily.com':
            return 'noindex';
        case 'production.fruitsfamily.com':
        case 'fruitsfamily.com':
        case 'www.fruitsfamily.com':
            return 'index';
        default:
            return 'index';
    }
}