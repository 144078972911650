import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './Popover.css';

/**
 * A component to show a Bootstrap popover.
 * @param children the children element to show in the popover button
 * @param className the element class property
 * @param options the Boostrap popover options
 * @param attributes extra html attributes
 * @return {*}
 * @constructor
 */
export default function Popover({ children, className, content, options, ...attributes }) {
    const hasDOM = typeof window !== 'undefined' && window.document && window.document.createElement;
    const [buttonRef, setButtonRef] = useState(null);
    const contentRef = useRef(hasDOM ? document.createElement('div') : null);

    useEffect(() => {
        if (hasDOM && buttonRef) {
            const element = $(buttonRef);
            element.popover({
                ...options,
                content: contentRef.current,
                html: true
            });

            return () => {
                element.popover('hide');
                element.popover('dispose');
            };
        }
    }, [buttonRef, contentRef, hasDOM, options]);

    return (
        <React.Fragment>
            <button type="button"
                    className={`Popover ${className}`}
                    data-toggle="popover"
                    ref={setButtonRef}
                    {...attributes}>
                {children}
            </button>
            {hasDOM ? ReactDOM.createPortal(content, contentRef.current) : null}
        </React.Fragment>
    );
}
Popover.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    content: PropTypes.node.isRequired,
    options: PropTypes.object
};

