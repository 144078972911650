import React from 'react';
import MountedWrapper from '../../component/mountedWrapper/MountedWrapper';
import Order from './Order';

export default function OrderWrapper() {
    return (
        <MountedWrapper>
            <Order/>
        </MountedWrapper>
    );
}