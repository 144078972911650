import React from 'react';
import PropTypes from 'prop-types';

import CardDeck from '../cardDeck/CardDeck';

import ProductsListItem from './productsListItem/ProductsListItem';
import './ProductsList.css';

/**
 * Products card deck component.
 */
export default function ProductsList({ itemsProperty, query, variables, limit, isEmptyMsg = true }) {
    return (
        <div className="ProductsList">
            <CardDeck itemsProperty={itemsProperty} query={query} rowClassName="row-cols-2 row-cols-md-3 row-cols-xl-4"
                      variables={variables} limit={limit} isEmptyMsg={isEmptyMsg}>
                {product => (
                    <div className="ProductsList-item">
                        <ProductsListItem product={product} />
                    </div>
                )}
            </CardDeck>
        </div>
    );
}
ProductsList.propTypes = {
    itemsProperty: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    variables: PropTypes.object,
    limit: PropTypes.number,
    isEmptyMsg: PropTypes.bool,
};
