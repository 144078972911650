import React from "react";
import PropTypes from "prop-types";

import './Button.css';

export default function Button({text, color = 'default', full = false, disabled = false, onClick, ...props}) {
    const buttonClass = `button ${color} ${full ? 'full' : ''}`;

    return <button className={buttonClass} disabled={disabled} onClick={onClick} {...props}>{text}</button>
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['default']),
    full: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

