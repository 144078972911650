import React from 'react';
import PropTypes from 'prop-types';
import './CheckoutProductDetails.css';
import {convertCondition} from "../../util/util";

/**
 * 상품 상세 정보 컴포넌트
 * @param product the checked out product
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckoutProductDetails({ product }) {
    return (
        <div className="CheckoutProductDetails">
            <img className="CheckoutProductDetails-image"
                 loading="lazy"
                 alt={"product_image"}
                 src={product.resizedSmallImages[0]}
            />
            <div className="CheckoutProductDetails-details">
                <h6 className={`CheckoutProductDetails-brand ${product.brand ? '' : 'CheckoutProductDetails-brand--missing'}`}>
                    {product.brand || 'No Brand'}
                </h6>
                <div className="CheckoutProductDetails-productTitle">
                    {product.title}
                </div>
                <div className="CheckoutProductDetails-condition">
                    {product.gender} {product.size} {product.condition ? `∙ ${convertCondition(product.condition)}` : ''}
                </div>
            </div>
        </div>
    );
}
CheckoutProductDetails.propTypes = {
    product: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        status: PropTypes.oneOf(['selling', 'sold']).isRequired,
        resizedSmallImages: PropTypes.arrayOf(PropTypes.string).isRequired,
        brand: PropTypes.string,
        seller: PropTypes.shape({
            user: PropTypes.shape({
                username: PropTypes.string.isRequired
            }).isRequired
        }),
        gender: PropTypes.string.isRequired,
        size: PropTypes.string,
        condition: PropTypes.string,
    }).isRequired
};
