import React from 'react';
import PropTypes from "prop-types";
import ImageCaption from "../../../component/imageCaption/ImageCaption";
import HorizontalProductsList from "../../home/horizontalProductsList/HorizontalProductsList";

/**
 * 컬렉션 추가 정보
 * @param extraInfo
 * @param userAgent
 * @returns {Element}
 * @constructor
 */
export default function CollectionExtraInfo({extraInfo, userAgent}) {
    return <div>
        {extraInfo.image &&
            <div className="Collection-extra-info-image">
                <ImageCaption url={extraInfo.image.url}
                              aspectRatio={extraInfo.image.aspectRatio}
                              caption={extraInfo.image.caption}  />
            </div>
        }
        {extraInfo.description &&
            <div className="Collection-description">
                {extraInfo.description}
            </div>
        }
        {extraInfo.list &&
            <div className="Collection-extra-info-list">
                <div className="Collection-extra-info-list-title">{extraInfo.list.title}</div>
                <div className="Collection-extra-info-list-subtitle">{extraInfo.list.subtitle}</div>
                <HorizontalProductsList
                    userAgent={userAgent}
                    rowClassName="row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5"
                    isCollectionProduct={true}
                    variables={{
                        collectionId: parseInt(extraInfo.list.collection.id),
                        filter: extraInfo.list.filter
                    }}
                />
            </div>
        }
    </div>
}

CollectionExtraInfo.propTypes = {
    extraInfo: PropTypes.object,
    userAgent: PropTypes.string.isRequired
};
