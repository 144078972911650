export default {
    apiURI(hostname) {
      let uri;
      switch (hostname) {
        case 'localhost':
          uri = 'http://localhost:4001/graphql';
          break;
        case 'staging.fruitsfamily.com':
          uri = 'https://web-server.staging.fruitsfamily.com/graphql';
          break;
        case 'production.fruitsfamily.com':
        case 'fruitsfamily.com':
        case 'www.fruitsfamily.com':
          uri = 'https://web-server.production.fruitsfamily.com/graphql';
          break;
        default:
          uri = 'http://localhost:4001/graphql';
          break;
      }
      return new URL(uri);
    },
    apiHost(api_env) {
      let uri;
      switch (api_env) {
        case 'localhost':
          uri = 'http://localhost:4001/graphql';
          break;
        case 'staging':
          uri = 'https://web-server.staging.fruitsfamily.com/graphql';
          break;
        case 'production':
          uri = 'https://web-server.production.fruitsfamily.com/graphql';
          break;
        default:
          uri = 'http://localhost:4001/graphql';
          break;
      }
      return new URL(uri);
    }
};
