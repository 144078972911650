import React from 'react';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

import Error from '../../component/error/Error';
import Loading from '../../component/loading/Loading';
import {PRODUCT_FRAGMENT} from '../../component/productPreview/ProductPreview';
import ProductsList from '../../component/productsList/ProductsList';
import {USER_FRAGMENT} from '../../component/userPreview/UserPreview';
import {collectionUrl, webappURL} from '../../util/url';

import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from '../product/Product';

import CollectionOpenGraph from './CollectionOpenGraph';
import CollectionTwitterCard from './CollectionTwitterCard';

import './Collection.css';
import PropTypes from 'prop-types';
import {isWithinInt32Range} from "../../util/util";
import CollectionExtraInfo from "./components/CollectionExtraInfo";
import {formatKoreanDate} from "../../util/date";
import ImageCaption from "../../component/imageCaption/ImageCaption";

// GraphQL queries
const GET_COLLECTION = gql`
    query GetCollection($collectionId: Int!) {
        getCollection(collection_id: $collectionId) {
            id
            title
            subtitle
            sub_header
            description
            resizedBigImage
            link
            featured_from
            featured_until
            updatedAt
            image_caption
            aspect_ratio
            extra_info
            seller {
                id
                user {
                   ...UserFragment
                   username
               }
            }
        }
    }
    ${USER_FRAGMENT}
`;
const GET_COLLECTION_PRODUCTS = gql`
    query GetCollectionProducts($collectionId: Int!, $offset: Int, $limit: Int) {
        getCollectionProducts(collection_id: $collectionId, offset: $offset, limit: $limit) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Category collection component.
 * @param hostname
 * @param userAgent
 */
export default function Collection({hostname, userAgent}) {
    const {collectionId: encodedCollectionId} = useParams();
    const collectionId = parseInt(encodedCollectionId, 36);

    if (!isWithinInt32Range(collectionId)) {
        return <div className="Error alert alert-danger" role="alert">잘못된 접근입니다.</div>
    }

    // fetch collection details
    const {loading, error, data} = useQuery(
        GET_COLLECTION,
        {
            variables: {
                collectionId
            }
        }
    );

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    // meta tag
    const metaTitle = `${data.getCollection.title} | 후루츠패밀리`;

    return (
        <section className="Collection">
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={data.getCollection.description}/>
                <link rel="canonical" href={webappURL(hostname) + collectionUrl(data.getCollection)}/>
                <link rel="alternate" type="application/atom+xml"
                      href={`${collectionUrl(data.getCollection)}/feed.atom`}/>
            </Helmet>
            <CollectionOpenGraph collection={data.getCollection} title={metaTitle} hostname={hostname}/>
            <CollectionTwitterCard collection={data.getCollection} title={metaTitle}/>

            <div className="Collection-container container">
                <div className="Collection-content-header">
                    <h2 className="Collection-subtitle">
                        {data.getCollection.subtitle}
                    </h2>
                    <h1 className="Collection-title">
                        {data.getCollection.title}
                    </h1>
                    {data.getCollection.sub_header && <h2 className="Collection-subheader">
                        {data.getCollection.sub_header}
                    </h2>}
                    <p className="Collection-date">
                        {formatKoreanDate(data.getCollection.featured_from)}
                    </p>
                </div>

                <ImageCaption
                    url={data.getCollection.resizedBigImage}
                    aspectRatio={data.getCollection.aspect_ratio}
                    caption={data.getCollection.image_caption}
                />

                <p className="Collection-description">
                    {data.getCollection.description}
                </p>

                {data.getCollection.extra_info && data.getCollection.extra_info.map((item, index) => {
                    return <div key={`collection_extrainfo_${index}`}>
                        <CollectionExtraInfo extraInfo={item} userAgent={userAgent} />
                    </div>
                })}

                <div className="Collection-products">
                    <ProductsList itemsProperty="getCollectionProducts"
                                  isEmptyMsg={false}
                                  query={GET_COLLECTION_PRODUCTS}
                                  variables={{
                                      collectionId
                                  }}/>
                </div>
            </div>
        </section>
    );
}
Collection.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired,
};
