import {gql} from 'apollo-boost';
import {useLazyQuery} from "@apollo/react-hooks";

export const GET_IS_EXISTING_EMAIL = gql`
  query getIsExistingEmail($email: String) {
    isExistingUser(email: $email)
  }
`;

export default function useCheckExistingEmail() {
    const [requestCheckExistingEmail, {data, error}] = useLazyQuery(GET_IS_EXISTING_EMAIL, {
        fetchPolicy: 'network-only',
    });

    const executeQuery = (email) => {
        requestCheckExistingEmail({ variables: { email } });
    };

    return [executeQuery, data, error];
}
