import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/react-hooks';

import Empty from '../empty/Empty';
import InfiniteScroll from '../infiniteScroll/InfiniteScroll';

import './CardDeck.css';

const LIMIT = 100;

/**
 * Card deck component.
 */
export default function CardDeck({ children, itemsProperty, query, rowClassName, variables, limit = LIMIT, onlyNetwork = false, isEmptyMsg}) {
    // fetch the items feed
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const { loading, data, fetchMore } = useQuery(
        query,
        {
            variables: {
                ...variables,
                offset: 0,
                limit: limit
            },
            fetchPolicy: onlyNetwork ? 'network-only' : 'cache-and-network', // force cache refresh to show new items
            onCompleted: (newData) => setHasMore(
                newData[itemsProperty].length === offset + limit
            ),
            onError: (error) => {
                console.error('Error loading the card deck', error);
            }
        }
    );
    const loadMore = () => {
        const newOffset = data[itemsProperty].length;
        setOffset(newOffset);

        fetchMore({
            variables: {
                offset: newOffset
            },
            updateQuery: (previousResult, { fetchMoreResult }) => ({
                ...previousResult,
                [itemsProperty]: [...previousResult[itemsProperty], ...fetchMoreResult[itemsProperty]]
            })
        });
    };

    // reset the offset on query change
    useEffect(() => setOffset(0), [query, variables]);

    // build the UI elements
    const renderItem = item => (
        <li className="CardDeck-item col mb-4" key={item.id}>
            {children(item)}
        </li>
    );
    const listItems = data && data[itemsProperty] && data[itemsProperty].length ?
        data[itemsProperty].map(renderItem) :
        loading ?
        null :
        (
            <li className="CardDeck-empty">
                {isEmptyMsg && <Empty /> }
            </li>
        );

    return (
        <div className="CardDeck">
            <InfiniteScroll loading={loading} hasMore={hasMore} loadMore={loadMore}>
                <ul className={`CardDeck-list list-unstyled row ${rowClassName}`}>
                    {listItems}
                </ul>
            </InfiniteScroll>
        </div>
    );
}
CardDeck.propTypes = {
    children: PropTypes.func.isRequired,
    itemsProperty: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    rowClassName: PropTypes.string.isRequired,
    variables: PropTypes.object,
    limit: PropTypes.number,
    onlyNetwork: PropTypes.bool,
    isEmptyMsg: PropTypes.bool,
};
