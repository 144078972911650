import React from 'react';
import {Link} from 'react-router-dom';

import {downloadUrl} from '../../util/url';

import nicepayEscrow from './assets/nicepayEscrow.png';
import './Footer.css';

/**
 * Footer page component.
 */
export default function Footer() {
    return (
        <footer className="Footer container mb-4 pt-4 mb-md-5 pt-md-5">
            <div className='Footer-border' />
            <div>
                <ul className="Footer-menu list-unstyled">
                    <li>
                        <a className="Footer-link"
                           href="https://intercom.help/fruits-family-co/articles/3683933"
                           rel="noopener noreferrer"
                           target="_blank">
                            이용약관
                        </a>
                    </li>
                    <li>
                        <a className="Footer-link"
                           href="https://intercom.help/fruits-family-co"
                           rel="noopener noreferrer"
                           target="_blank">
                            고객센터
                        </a>
                    </li>
                    <li>
                        <a className="Footer-link"
                           href="https://support.fruitsfamily.com/sell"
                           rel="noopener noreferrer"
                           target="_blank">
                            판매
                        </a>
                    </li>
                    <li>
                        <a className="Footer-link"
                           href="https://intercom.help/fruits-family-co/articles/9669237"
                           rel="noopener noreferrer"
                           target="_blank">
                            개인정보 처리방침
                        </a>
                    </li>
                    <li>
                        <a className="Footer-link"
                           href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7558601442"
                           rel="noopener noreferrer"
                           target="_blank">
                            사업자 정보
                        </a>
                    </li>
                    <li>
                        <Link className="Footer-link"
                              to={downloadUrl()}>
                            다운로드
                        </Link>
                    </li>
                    <li>
                        <a className="Footer-link"
                           href="https://www.instagram.com/fruitsfamily.co/"
                           rel="noopener noreferrer"
                           target="_blank">
                            인스타그램
                        </a>
                    </li>
                    <li>
                        <a className="Footer-link"
                           href="https://www.facebook.com/FruitsFamily-108768747380238"
                           rel="noopener noreferrer"
                           target="_blank">
                            페이스북
                        </a>
                    </li>
                    <li>
                        <a className="Footer-link"
                           href="https://m.post.naver.com/fruitsfamilyco"
                           rel="noopener noreferrer"
                           target="_blank">
                            네이버 포스트
                        </a>
                    </li>
                </ul>
            </div>
            <div className="Footer-legal justify-content-between align-items-center">
                <div>
                    <p className='Footer-legal-content'>
                        (주)후루츠패밀리컴퍼니 · 대표이사 이재범 / 소재지: 서울특별시 용산구 한강대로 328 / 사업자 등록번호: 755-86-01442&nbsp;
                        <a className='Footer-legal-in-link' href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7558601442"
                           rel="noopener noreferrer"
                           target="_blank">사업자 정보확인</a>
                        &nbsp;통신판매업신고:
                        2019-서울용산-0723 호 / 고객센터: 070-4466-3377
                        / <a className='Footer-legal-in-link' href="mailto:support@fruitsfamily.com">support@fruitsfamily.com</a>
                    </p>
                    <p className='Footer-legal-content'>
                        Copyright © FruitsFamily Company Inc. All right reserved
                    </p>
                    <p>
                        후루츠패밀리(주)는 통신판매중개자로서 거래 당사자가 아닙니다. 상품, 상품정보, 거래에 관한 의무와 책임은 각 판매자에게 있으며, 후루츠패밀리(주)는
                        원칙적으로 판매
                        회원과 구매 회원 간의 거래에 대하여 책임을 지지 않습니다. 다만, 후루츠패밀리에서 직접 판매하는 상품에 대한 책임은 후루츠패밀리(주)에 있습니다.
                    </p>
                </div>
                <div className='Footer-escrow-container'>
                    <a href="https://pg.nicepay.co.kr/issue/IssueEscrow.jsp?Mid=IMPfrui01m&CoNo=7558601442"
                       rel="noopener noreferrer"
                       target="_blank">
                        <img src={nicepayEscrow} alt={'escrow mark'} className='Footer-escrow-img'/>
                    </a>
                </div>
            </div>
        </footer>
    );
}

