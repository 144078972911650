import React from 'react';
import PropTypes from 'prop-types';
import {useSearchInputStore} from "../../../store/useSearchInputStore";
import {useSearchModalStore} from "../../../store/useSearchModalStore";
import useSearchSuggestion from "../../../query/useSearchSuggestion";
import {useNavigate} from "react-router-dom";
import {brandUrl} from "../../../util/url";
import FollowButton from "../../../component/followButton/FollowButton";
import MatchingText from "../../../component/matchingText/MatchingText";
import {logSearch} from "../../../util/analytics";

import './SearchBrandList.scss';


/**
 * Search brand list
 * @param hostname
 * @param userAgent
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchBrandList({hostname, userAgent}) {
    const { searchQuery, initSearchQuery } = useSearchInputStore();
    const { closeModal } = useSearchModalStore();
    const navigate = useNavigate();

    const {
        data: suggestionsData,
    } = useSearchSuggestion(searchQuery);

    const handleOnClick = (search) => {
        initSearchQuery();
        logSearch(search);
        navigate(`${brandUrl(search)}?isSearch=true`);
        closeModal();
    }

    return <>
            {searchQuery.length > 0 && suggestionsData && suggestionsData.searchSuggestions.length > 0 &&
                <div>
                    <div className='SearchBrandList-title'>
                        Brands
                    </div>
                    {suggestionsData.searchSuggestions.map((suggestion) => (
                        <div key={suggestion.id} className='SearchBrandList-card' onClick={() => {
                            handleOnClick(suggestion.name);
                        }}>
                            <div className='SearchBrandList-card-content'>
                                <div className='SearchBrandList-card-name'>
                                    <div className='name'>
                                        <MatchingText fullText={suggestion.name} matchText={searchQuery} />
                                    </div>
                                    <div className='sub-name'>
                                        {suggestion.name_kr && <MatchingText fullText={suggestion.name_kr} matchText={searchQuery} />}
                                    </div>
                                </div>
                                <div>
                                    <FollowButton hostname={hostname} userAgent={userAgent}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
    </>;
}

SearchBrandList.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
