import React from 'react';
import {useSearchInputStore} from "../../../store/useSearchInputStore";

import './SearchKeywordDisplay.scss';
import {searchUrl} from "../../../util/url";
import {useSearchModalStore} from "../../../store/useSearchModalStore";
import {useNavigate} from "react-router-dom";
import {logSearch} from "../../../util/analytics";

/**
 * Search keyword display
 * @returns {false|JSX.Element}
 * @constructor
 */
export default function SearchKeywordDisplay() {
    const { searchQuery } = useSearchInputStore();
    const { closeModal } = useSearchModalStore();
    const navigate = useNavigate();

    const handleOnClick = () => {
        logSearch(searchQuery);
        navigate(searchUrl(searchQuery));
        closeModal();
    }

    return searchQuery.length > 0 &&
        <div onClick={handleOnClick} className='SearchKeywordDisplay-container'>
                <div className='keyword'>{`"${searchQuery}"`}</div>
                <div className='postKeyword'>검색하기</div>
        </div>
}
