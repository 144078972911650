import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {productUrl, webappURL} from '../../util/url';

/**
 * Open Graph metadata component for a product.
 * @param product a product for which to generate metadata
 * @param title meta title
 * @param hostname
 * @return {*}
 * @constructor
 */
export default function ProductOpenGraph({ product, title, hostname }) {
    const images = product.resizedBigImages.map(image => (
        <meta property="og:image"
              content={image}
              key={image} />
    ));

    return (
        <Helmet>
            {/* Cf schema at https://developers.facebook.com/docs/payments/product/,
              * https://developers.facebook.com/docs/marketing-api/catalog-setup/update-options/#og-required-tags and
              * https://webcode.tools/open-graph-generator/product-item
              */}
            <meta property="fb:app_id" content="330702484245822" />
            <meta property="og:site_name" content="FruitsFamily" />
            <meta property="og:type" content="product.item" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={product.description} />
            <meta property="og:url" content={webappURL(hostname) + productUrl(product)} />
            {images}
            {product.brand && <meta property="product:brand" content={product.brand} />}
            <meta property="product:availability" content={product.status === 'sold' ? 'out of stock' : 'in stock'} />
            <meta property="product:condition" content="used" /> {/* TODO store the condition in the product */}
            <meta property="product:price:amount"  content={product.price} />
            <meta property="product:price:currency" content="KRW" /> {/* TODO store the currency in the product */}
            <meta property="product:retailer_item_id" content={product.id} />
        </Helmet>
    );
}
ProductOpenGraph.propTypes = {
    product: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        brand: PropTypes.string,
        price: PropTypes.number.isRequired,
        status: PropTypes.oneOf(['selling', 'sold']).isRequired,
        resizedBigImages: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
};
