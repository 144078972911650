import {isAndroid, isIOS} from './navigator';

// app install parameters
const LAUNCH_APP_STORE = 'launch_app_store';
const APP_STORE_URL = new URL('https://itunes.apple.com/app/fruitsfamily/id1466193804');
const GOOGLE_PLAY_URL = new URL('https://play.google.com/store/apps/details?id=com.fruitsfamily');

// ios install tracking
// cf https://developers.google.com/analytics/solutions/ios-install-tracking
const APP_GOOGLE_TRACKING_ID = 'G-52074PX7L0';
const IOS_APP_ID = 'com.fruitsFamily.fruitsFamily';
const APP_STORE_INSTALL_TRACKING = new URL('https://click.google-analytics.com/redirect');

// conversion from utm parameters to google analytics measurement protocol parameters
// cf https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#trafficsources
const UTM_TO_ANALYTICS_PARAMETERS = {
    idfa: 'idfa', // Apple identifier for advertising (IDFA)
    utm_campaign: 'cn', // campaign name
    utm_source: 'cs', // campaign source
    utm_medium: 'cm', // campaign medium
    utm_term: 'ck', // campaign keyword
    utm_content: 'cc', // campaign content
    gclid: 'gclid' // Google Ads auto-tagging parameter
};

/**
 * Extract the marketing parameters from the current location.
 * @param location a react-router location object
 * @return the marketing parameters
 */
export function extractMarketingParameters(location) {
    const searchParams = new URLSearchParams(location.search);
    const marketingParameters = Object.fromEntries(
        Array.from(searchParams.entries())
            .filter(([key]) => key in UTM_TO_ANALYTICS_PARAMETERS) // only keep known parameters
    );

    return marketingParameters;
}

/**
 * Generate the google play url with marketing campaign attribution.
 * @param marketingParameters a dictionary with the marketing parameters for the current location
 * @return {URL} the generated url
 */
export function googlePlayUrl(marketingParameters) {
    const hasMarketingParameters = Object.keys(marketingParameters).length > 0;

    // prepare the google play url
    const url = new URL(GOOGLE_PLAY_URL.toString());
    if (hasMarketingParameters) {
        // re-encode the marketing campaign parameters
        const referrer = new URLSearchParams(Object.entries(marketingParameters));

        url.searchParams.set('referrer', referrer.toString());
    }

    return url;
}

/**
 * Generate the app store url with marketing campaign attribution.
 * @param marketingParameters a dictionary with the marketing parameters for the current location
 * @return {URL} the generated url
 */
export function appStoreUrl(marketingParameters) {
    const hasMarketingParameters = Object.keys(marketingParameters).length > 0;

    // prepare the app store url
    if (hasMarketingParameters) {
        // redirect through the Google Analytics click server
        // https://developers.google.com/analytics/solutions/ios-install-tracking
        const url = new URL(APP_STORE_INSTALL_TRACKING);
        url.searchParams.set('tid', APP_GOOGLE_TRACKING_ID); // google analytics tracking ID
        url.searchParams.set('aid', IOS_APP_ID); // app id
        url.searchParams.set('url', APP_STORE_URL.toString()); // redirect url to itunes
        Object.entries(marketingParameters)
            .forEach(([parameter, value]) => {
                url.searchParams.set(UTM_TO_ANALYTICS_PARAMETERS[parameter], value);
            });

        return url;
    } else {
        // return the base app store url
        return APP_STORE_URL;
    }
}

/**
 * Redirect to the app store if requested by a location url parameter.
 * @param history the browser history object
 * @param location the browser location object
 * @param userAgent the user agent
 */
export function launchAppStore (history, location, userAgent) {
    const href = new URL(location.href);
    if (href.searchParams.has(LAUNCH_APP_STORE)) {
        href.searchParams.delete(LAUNCH_APP_STORE);
        history.replaceState({}, document.title, href.toString());

        if (isIOS(userAgent)) {
            const marketingParameters = extractMarketingParameters(location);

            location.href = appStoreUrl(marketingParameters).toString();
        }
    }
}

/**
 * Builds iOS universal links and Android app links.
 * @param hostname the webapp hostname
 * @param path the path for the app link
 * @param userAgent the user agent
 * @return an external URL or a webapp pathname for the app link
 */
export default function appLink(hostname, path, userAgent) {
    // prepare the app link url
    let applinkUrl;
    if (isAndroid(userAgent)) {
        applinkUrl = new URL(`intent://${hostname}${path}`);

        const marketingParameters = extractMarketingParameters(applinkUrl);
        const hasMarketingParameters = Object.keys(marketingParameters).length > 0;

        if (hasMarketingParameters) {
            const referrer = new URLSearchParams(Object.entries(marketingParameters));
            applinkUrl.hash = `Intent;scheme=https;package=com.fruitsfamily;S.market_referrer=${encodeURIComponent(referrer.toString())};end`;
        } else {
            applinkUrl.hash = `Intent;scheme=https;package=com.fruitsfamily;end`;
        }
    } else if (isIOS(userAgent)) {
        applinkUrl = new URL(`https://${hostname}${path}`);
        applinkUrl.hostname = 'applink.' + applinkUrl.hostname.replace(/^www\./i, '');
    } else { // fallback for desktop
        applinkUrl = '/download';
    }

   return applinkUrl;
}
