import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";

import SignUpForm from "./SignUpForm";
import {useNavigate, useSearchParams} from "react-router-dom";
import {homeUrl} from "../../../util/url";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import useSignInBySns from "../../../query/useSignInBySns";
import {useAuthStore} from "../../../store/useAuthStore";
import {logSignUp} from "../../../util/analytics";
import {META_TAG_TITLE} from "../../../constants/constants";


const MUTATION_SIGN_UP_SNS = gql`
  mutation signupBySnsV2(
    $snsType: String!
    $snsToken: String!
    $userName: String!
    $phone: String!
    $verificationCode: String!
    $gender: String
    $is_allow_ads: Boolean
  ) {
    signupBySnsV2(
      sns_type: $snsType
      sns_token: $snsToken
      username: $userName
      phone: $phone
      phoneIdentificationNumber: $verificationCode
      gender: $gender
      is_allow_ads: $is_allow_ads
    ) {
      id
    }
  }
`;

export default function SignUpSns() {
    const navigate = useNavigate();
    const {login} = useAuthStore();
    const [queryParameters] = useSearchParams();
    const [signUpSns] = useMutation(MUTATION_SIGN_UP_SNS);
    const [singInSns, responseSingInSns, singInSnsError] = useSignInBySns();
    const snsType = queryParameters.get('snsType');
    const token = queryParameters.get('token');

    useEffect(() => {
        if (!snsType || !token) {
            navigate(homeUrl());
        }
    }, []);

    const onSubmit = (data) => {
        signUpSns({
            variables: {
                snsType: snsType,
                snsToken: token,
                userName: data.nickname,
                phone: data.phoneNumber,
                verificationCode: data.authNumber,
                is_allow_ads: data.isAllowAd,
            }
        }).then(() => {
            logSignUp(snsType.toLocaleLowerCase());
            singInSns(snsType, token);
        }).catch(() => {

        });
    }

    useEffect(() => {
        if (responseSingInSns) {
            console.log(responseSingInSns)
            login(responseSingInSns.signinBySnsV2)
            navigate(homeUrl());
        }
    }, [responseSingInSns, singInSnsError]);

    return <>
        <Helmet>
            <title>회원가입 | {META_TAG_TITLE}</title>
            <meta name="description"
                  content="유니크한 빈티지, 세컨핸드부터 구하기 힘든 하이엔드 디자이너 브랜드까지 후루츠에서 한번에." />
        </Helmet>
        <SignUpForm isSns={true} submit={(data) => {
            onSubmit(data);
        }}/>
    </>
}
