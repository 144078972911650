// Korean won currency code, cf http://en.wikipedia.org/wiki/ISO_4217
const KRW = 'KRW';


/**
 * config userId to analytics.
 * @param userId
 * @returns {Promise<void>}
 */
export async function logSetUserId(userId) {
    window.gtag('config', {user_id: userId});
}

/**
 * log a login event to analytics.
 * @param method - login method
 * @returns {Promise<void>}
 */
export async function logLogin(method) {
    window.gtag('event', 'login', {
        method: method,
    });
}

/**
 * log a sign up event to analytics.
 * @param method
 * @returns {Promise<void>}
 */
export async function logSignUp(method) {
    window.gtag('event', 'sign_up', {
        method: method,
    });
}


/**
 * Log a payment details event to analytics.
 * @param {string} listName The item list name for the ecommerce analytics events.
 * @param {{id: string, title: string, category: string, brand: string, price: number}} product The purchased product.
 * @param {string} paymentType The payment method.
 * @returns {Promise<void>}
 */
export async function logAddPaymentInfo(listName, product, paymentType) {
    window.gtag('event', 'add_payment_info', {
        currency: KRW,
        value: product.price,
        payment_type: paymentType,
        items: [{
            item_list_name: listName,
            item_id: product.id,
            item_name: product.title,
            item_category: product.category,
            item_brand: product.brand ? product.brand : undefined,
            quantity: 1,
            price: product.price
        }]
    });
}

/**
 * Log a shipping details event to analytics.
 * @param {string} listName The item list name for the ecommerce analytics events.
 * @param {{id: string, title: string, category: string, brand: string, price: number}} product The viewed product.
 * @returns {Promise<void>}
 */
export async function logAddShippingInfo(listName, product) {
    window.gtag('event', 'add_shipping_info', {
        currency: KRW,
        value: product.price,
        items: [{
            item_list_name: listName,
            item_id: product.id,
            item_name: product.title,
            item_category: product.category,
            item_brand: product.brand ? product.brand : undefined,
            quantity: 1,
            price: product.price
        }]
    });
}

/**
 * 결제를 시작할 때
 * @param {string} listName The item list name for the ecommerce analytics events.
 * @param {{id: string, title: string, category: string, brand: string, price: number}} product The viewed product.
 * @returns {Promise<void>}
 */
export async function logBeginCheckout(listName, product) {
    window.gtag('event', 'begin_checkout', {
        currency: KRW,
        value: product.price,
        items: [{
            item_list_name: listName,
            item_id: product.id,
            item_name: product.title,
            item_category: product.category,
            item_brand: product.brand ? product.brand : undefined,
            quantity: 1,
            price: product.price
        }]
    });
}

/**
 * 구매를 완료할 때
 * @param {string} listName The item list name for the ecommerce analytics events.
 * @param {{id: string, title: string, category: string, brand: string, price: number}} product The purchased product.
 * @param {string} transactionId The transaction id.
 * @param {number} paidAmount The total amount paid.
 * @param {number} shipping The shipping cost.
 * @returns {Promise<void>}
 */
export async function logPurchase(listName, product, transactionId, paidAmount, shipping) {
    window.gtag('event', 'purchase', {
        currency: KRW,
        value: paidAmount,
        shipping: shipping,
        transaction_id: transactionId,
        items: [{
            item_list_name: listName,
            item_id: product.id,
            item_name: product.title,
            item_category: product.category,
            item_brand: product.brand ? product.brand : undefined,
            quantity: 1,
            price: product.price
        }]
    });
}

/**
 * 상품을 조회할 때
 * @param {string} listName The item list name for the ecommerce analytics events.
 * @param {{id: string, title: string, category: string, brand: string, price: number}} product The viewed product.
 * @returns {Promise<void>}
 */
export async function logViewItem(listName, product) {
    window.gtag('event', 'view_item', {
        items: [{
            item_list_name: listName,
            item_id: product.id,
            item_name: product.title,
            item_category: product.category,
            item_brand: product.brand ? product.brand : undefined,
            quantity: 1,
            price: product.price
        }]
    });
}

/**
 * 검색어 조회 할때
 * @param keyword
 * @returns {Promise<void>}
 */
export async function logSearch(keyword) {
    window.gtag('event', 'search', {
        search_term: keyword
    });
}
