import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import './Error.css';

export default function Error({ error }) {
    return (
        <div className="Error alert alert-danger" role="alert">
            {error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].statusCode && (
                <Helmet>
                    <meta httpEquiv="Status" content={error.graphQLErrors[0].statusCode} />
                </Helmet>
            )}
            <strong>에러</strong>
            {' '}
            {error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].message ?
                error.graphQLErrors[0].message :
                error.message}
        </div>
    );
}
Error.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
        graphQLErrors: PropTypes.arrayOf(
            PropTypes.shape({
                message: PropTypes.string,
                statusCode: PropTypes.number
            })
        )
    }).isRequired
};
