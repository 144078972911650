import {gql} from "apollo-boost";
import {useLazyQuery} from "@apollo/react-hooks";

export const SIGN_IN_SNS = gql`
  query signinBySnsV2(
    $snsType: String!
    $token: String!
  ) {
    signinBySnsV2(type: $snsType, token: $token) {
      id
      email
      login_type
      username
      nickname
      resizedSmallImage
      resizedBigImage
      access_token
      refresh_token
      bio
      phone
      following_count
      follower_count
      seller {
        id
        income
        rating
        sold_count
        fee
        seller_account {
          bank_name
          account_holder_name
          bank_account
        }
      }
    }
  }
`;

/**
 * Social 로그인 API
 */
export default function useSignInBySns() {
    const [signInSns, {data, error}] = useLazyQuery(SIGN_IN_SNS, {
        fetchPolicy: 'network-only',
    });

    const executeQuery = (snsType, token) => {
        signInSns({ variables: { snsType: snsType, token: token } });
    };

    return [executeQuery, data, error];
}
