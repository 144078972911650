import React from 'react';
import {gql} from 'apollo-boost';

import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from '../../page/product/Product';
import CardDeck from '../cardDeck/CardDeck';
import OrderItem from './OrderItem';

const GET_MY_PURCHASED_ORDER = gql`
    query getMyPurchasedOrder($offset: Int, $limit: Int) {
        seeMyPurchasedOrders(offset: $offset, limit: $limit) {
            id
            createdAt
            deliveredAt
            status
            product {
                ...ProductDetailsPreloadFragment
            }
            seller {
                id
                rating
                user {
                    id
                    username
                    nickname
                    bio
                    resizedSmallImage
                    resizedBigImage
                    follower_count
                }
            }
        }
    }
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * 구매내역 페이지에 사용되는 리스트 컴포넌트
 * @returns {JSX.Element}
 */
export default function OrderList() {
    return (
        <div className="OrderList">
            <CardDeck itemsProperty={'seeMyPurchasedOrders'} query={GET_MY_PURCHASED_ORDER}
                      rowClassName="row-cols-1" variables={{}} onlyNetwork={true}>
                {order => (
                    <div className="OrderList-item">
                        <OrderItem order={order} />
                    </div>
                )}
            </CardDeck>
        </div>
    );
}
