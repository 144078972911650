import React from 'react';
import ReactDOM from 'react-dom';

/* third party javascript */
import 'bootstrap';

/* common stylesheets */
import './styles/overrides_bootstrap/bootstrap.scss';
import './styles/font.css';
import './styles/overrides_bootstrap.css';
import './styles/overrides_html.css';
import './styles/index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.hydrate(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
