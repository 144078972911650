import React from 'react';
import PropTypes from 'prop-types';

import ProductPreview from '../../productPreview/ProductPreview';
import {currencyFormat} from '../../../util/locale';

import './ProductsListItem.css';
import {Link} from "react-router-dom";
import {brandUrl} from "../../../util/url";
import IconHeart from "../assets/heartIcon.svg";

/**
 * Products list item component.
 */
export default function ProductsListItem({ product }) {
    return (
        <div className="ProductsListItem">
            <div className="ProductsListItem-1by1">
                <div className="ProductsListItem-1by1-content">
                    <ProductPreview product={product}/>
                </div>
            </div>
            <div className="ProductsListItem-brand-size">
                <div className="ProductsListItem-brand-container">
                    {product.brand ? (
                        <Link to={brandUrl(product.brand)} className="ProductsListItem-brand-link text-muted">
                            <h6 className="ProductsListItem-brand">
                                {product.brand}
                            </h6>
                        </Link>
                    ) : (
                        <h6 className="ProductsListItem-brand ProductsListItem-brand--missing">
                            No Brand
                        </h6>
                    )}
                </div>
                <div className="ProductsListItem-size">
                    {product.size}
                </div>
            </div>
            <h7 className="ProductsListItem-title">
                {product.title}
            </h7>
            {product.condition === 'NEW' &&
                <div className="ProductsListItem-new-product">
                    새상품
                </div>
            }
            <div className="ProductsListItem-price-container">
                {product.discount_rate && <div className="ProductsListItem-discount-rate">
                    {product.discount_rate}%
                </div>}

                <div className="ProductsListItem-price font-proxima">
                    {currencyFormat(product.price, 'KRW')}
                </div>
            </div>
            {product.like_count > 0 && <div className="ProductsListItem-like">
                <img src={IconHeart} alt="likeicon"/>
                <p>{product.like_count}</p>
            </div>}
        </div>
    );
}
ProductsListItem.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        status: PropTypes.oneOf(['selling', 'sold']).isRequired,
        brand: PropTypes.string,
        external_url: PropTypes.string,
        price: PropTypes.number.isRequired,
        resizedSmallImages: PropTypes.arrayOf(PropTypes.string).isRequired,
        size: PropTypes.string,
        condition: PropTypes.string,
        like_count: PropTypes.number,
        discount_rate: PropTypes.number,
    }).isRequired
};
