import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import {productUrl, sellerUrl} from '../../util/url';
import UserPreview from '../userPreview/UserPreview';
import './OrderItem.css'

/**
 * 구매내역 리스트에 사용되는 컴포넌트
 * @param order 결제 데이터
 * @returns {JSX.Element}
 */
export default function OrderItem({order}) {
    const navigate = useNavigate();

    const processStatusMessage = (status) => {
        switch (status) {
            case "pending":
                return (
                    <span className="Order-status pending">배송준비중</span>
                );
            case "shipping":
                return (
                    <span className="Order-status shipping">구매확정 해주세요</span>
                );
            case "completed":
                return (
                    <span className="Order-status completed">거래 완료</span>
                );
            case "cancelled":
                return (
                    <span className="Order-status cancelled">취소 완료</span>
                );
            case "settled":
                return (
                    <span className="Order-status completed">거래 완료</span>
                );
        }
    };

    return (
        <div className="OrderItem">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-row align-items-center flex-grow-1">
                    <Link to={sellerUrl(order.seller)}>
                        <div className="Order-Profile-image">
                            <UserPreview user={{...order.seller.user, resizedSmallImage: order.seller.user.resizedSmallImage}} />
                        </div>
                    </Link>
                    <div className="d-flex flex-column Order-Info flex-grow-1" onClick={() => navigate(`/my/purchased/${order.id}`)}>
                        <span className="Order-Seller">{order.seller.user.nickname}</span>
                        <span>
                            <span className="Order-Date-Status">
                                {order.status === "shipping" || order.status === "completed" || order.status === "settled"
                                ? "[배송일]"
                                : "[결제일]"}
                            </span>{"   "}
                            <span className="Order-Date">
                                {order.deliveredAt? dayjs(order.deliveredAt).format('YYYY.MM.DD')
                                    : dayjs(order.createdAt).format('YYYY.MM.DD')}
                            </span>
                        </span>
                        <span>{processStatusMessage(order.status)}</span>
                    </div>
                </div>
                <Link to={productUrl(order.product)}>
                    <img className="OrderItem-Product-image"
                         loading="lazy"
                         alt=""
                         src={order.product.resizedSmallImages[0]} />
                </Link>
            </div>

        </div>
    );
}

OrderItem.propTypes = {
    order: PropTypes.object.isRequired
};