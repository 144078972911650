import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import './ErrorPage.css';

/**
 * Not Found page.
 */
export default function ErrorPage() {
    // build the UI elements
    return (
        <section className="container">
            <Helmet>
                <title>후루츠패밀리</title>
                <meta name="description" content={'후루츠패밀리 | 일시적인 문제가 발생하였습니다.'}/>
                <meta name="robots" content="noindex"/>
            </Helmet>

            <h2 className="ErrorPage-title">
                일시적인 문제가 발생하였습니다.
            </h2>

            <p className="ErrorPage-description">
                잠시 후 다시 시도 해주세요.<br />
                <Link to="/">메인으로 돌아가기</Link>
            </p>
        </section>
    );
}
