import React from 'react';
import {Helmet} from "react-helmet";

import SignUpForm from "./SignUpForm";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import useLoginEmail from "../../../query/useLoginEmail";
import {logSignUp} from "../../../util/analytics";
import {META_TAG_TITLE} from "../../../constants/constants";


const MUTATION_SIGN_UP_EMAIL = gql`
  mutation signupV2(
    $email: String!
    $password: String!
    $userName: String!
    $phone: String!
    $verificationCode: String!
    $gender: String
    $is_allow_ads: Boolean
  ) {
    signupV2(
      email: $email
      password: $password
      username: $userName
      phone: $phone
      phoneIdentificationNumber: $verificationCode
      gender: $gender
      is_allow_ads: $is_allow_ads
    ) {
      id
    }
  }
`;

export default function SignUp() {
    const [signUpEmail] = useMutation(MUTATION_SIGN_UP_EMAIL);
    const [loginEmail] = useLoginEmail();

    const onSubmit = (data) => {
        signUpEmail({
            variables: {
                email: data.email,
                password: data.password,
                userName: data.nickname,
                phone: data.phoneNumber,
                verificationCode: data.authNumber,
                is_allow_ads: data.isAllowAd,
            }
        }).then(() => {
            logSignUp('email');
            loginEmail({
                variables: {
                    email: data.email,
                    password: data.password,
                }
            })
        }).catch(() => {

        });
    }

    return <>
        <Helmet>
            <title>회원가입 | {META_TAG_TITLE}</title>
            <meta name="description"
                  content="유니크한 빈티지, 세컨핸드부터 구하기 힘든 하이엔드 디자이너 브랜드까지 후루츠에서 한번에." />
        </Helmet>
        <SignUpForm isSns={false} submit={(data) => {
        onSubmit(data);
    }} />
    </>
}

