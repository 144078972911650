import {productUrl, sellerUrl} from './url';

export const SCHEMA_CONTEXT = {
    '@context': 'https://schema.org/'
};

export function organizationSchemaOrg(properties) {
    return  {
        ...properties,
        '@type': 'Organization', // Naver channels don't seem to be compatible the more precise Corporation type
        name: 'FruitsFamily',
        legalName: '(주)후루츠패밀리컴퍼니',
        logo: 'https://fruitsfamily.com/logo512.png',
        url: 'https://fruitsfamily.com',
        sameAs: [
            'https://www.instagram.com/fruitsfamily.co/',
            'https://www.facebook.com/FruitsFamily-108768747380238/',
            'https://itunes.apple.com/app/fruitsfamily/id1466193804',
            'https://play.google.com/store/apps/details?id=com.fruitsfamily'
        ]
    };
}

export function reviewSchemaOrg(reviews, properties) {
    return  {
        ...properties,
        '@type': 'Review',
        reviewRating: {
            '@type': 'Rating',
            ratingValue: reviews[0].rating
        },
        author: {
            '@type': 'Person',
            name: reviews[0].user.username
        },
        reviewBody: reviews[0].text,
        datePublished: reviews[0].createdAt
    };
}

export function aggregateRatingSchemaOrg(product, properties) {
    if (product.seller.review_count > 0) {
        return {
            ...properties,
            '@type': 'AggregateRating',
            ratingValue: product.seller.rating,
            reviewCount: product.seller.review_count,
        };
    }
}

export function sellerSchemaOrg(seller, properties) {
    return {
        ...properties,
        '@type': 'Store',
        name: seller.user.nickname,
        alternateName: `@${seller.user.username}`,
        description: seller.user.bio,
        image: seller.user.resizedBigImage,
        mainEntityOfPage: sellerUrl(seller),
        aggregateRating: seller.review_count > 0 ?
            {
                '@type': 'AggregateRating',
                ratingValue: seller.rating,
                reviewCount: seller.review_count,
            } : undefined
    };
}


export function productSchemaOrg(product, properties) {
    return {
        ...properties,
        '@type': 'Product',
        name: product.title,
        image: product.resizedBigImages,
        description: product.description,
        sku: product.id,
        brand: product.brand ?
            {
                '@type': 'Brand',
                name: product.brand
            } :
            undefined,
        category: product.category,
        mainEntityOfPage: productUrl(product),
        review: product.seller.reviews && product.seller.reviews.length ?
            reviewSchemaOrg(product.seller.reviews) :
            undefined,
        aggregateRating: aggregateRatingSchemaOrg(product),
        offers: {
            '@type': 'Offer',
            url: productUrl(product),
            priceCurrency: 'KRW', // TODO store the currency in the product
            price: product.price,
            itemCondition: product.condition === 'NEW' ? 'https://schema.org/NewCondition' : 'https://schema.org/UsedCondition',
            availability: product.status === 'sold' ? 'https://schema.org/SoldOut' : 'https://schema.org/InStock',
            seller: sellerSchemaOrg(product.seller)
        }
    };
}
