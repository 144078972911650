import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import MobileMaxWidthWrapper from "../../../component/mobileMaxWidthWrapper/MobileMaxWidthWrapper";
import {FormProvider, useForm} from "react-hook-form";
import FormInput from "../../../component/rhf/formInput/FormInput";
import Button from "../../../component/button/Button";
import FormCheckbox from "../../../component/rhf/formCheckBox/FormCheckBox";
import useRequestVerificationCode from "../../../query/useRequestVerificationCode";
import useCheckVerificationCode from "../../../query/useCheckVerificationCode";
import useCheckExistingEmail from "../../../query/useCheckExistingEmail";
import useCheckExistingUserName from "../../../query/useCheckExistingUserName";
import {getGraphQLErrorMsg} from "../../../util/util";
import {EMAIL_VALIDATION, NICKNAME_VALIDATION, PASSWORD_VALIDATION} from "../../../constants/validation_constants";
import _ from "lodash";
import toast from "react-hot-toast";

import './SignUpForm.css';

export default function SignUpForm({isSns = false, submit}) {
    const methods = useForm({mode :"onChange"});
    const { register, handleSubmit, watch, formState: { errors, isValid }, setValue, setError, setFocus, clearErrors} = methods;

    const email = watch('email');
    const nickname = watch('nickname');
    const phoneNumber = watch('phoneNumber');
    const authNumber = watch('authNumber');
    const allCheck = watch('allCheck');
    const isOverAge = watch('isOverAge');
    const agreeService = watch('agreeService');
    const agreePrivacy = watch('agreePrivacy');
    const isAllowAd = watch('isAllowAd');

    //이메일 체크 - SNS 가입시 이메일 체크 안함
    const [isEmailVerified, setIsEmailVerified] = useState(isSns);
    const [requestCheckExistingEmail, responseCheckExistingEmail, checkExistingEmailError] = useCheckExistingEmail();

    const handleOnBlurEmail = () => {
        if (!_.isEmpty(email) && errors.email === undefined) {
            requestCheckExistingEmail(email);
        }
    }

    useEffect(() => {
        if (responseCheckExistingEmail) {
            setIsEmailVerified(!responseCheckExistingEmail.isExistingUser);

            if (responseCheckExistingEmail.isExistingUser) {
                setError('email', { message : '존재하는 계정입니다.'});
            }
        }

        if (checkExistingEmailError) {
            setIsEmailVerified(false);
            setError('email', { message : getGraphQLErrorMsg(checkExistingEmailError.message)});
        }
    }, [responseCheckExistingEmail, checkExistingEmailError]);

    //유저네임 체크
    const [isUserNameVerified, setIsUserNameVerified] = useState(false);
    const [requestCheckExistingUserName, responseCheckExistingUserName, checkExistingUserNameError] = useCheckExistingUserName();

    const handleOnBlurUserName = () => {
        if (!_.isEmpty(nickname) && errors.nickname === undefined) {
            requestCheckExistingUserName(nickname);
        }
    }

    useEffect(() => {
        if (responseCheckExistingUserName) {
            setIsUserNameVerified(!responseCheckExistingUserName.isExistingUser);

            if (responseCheckExistingUserName.isExistingUser) {
                setError('nickname', { message : '중복된 닉네임입니다.'});
            }
        }

        if (checkExistingUserNameError) {
            setIsUserNameVerified(false);
            setError('nickname', { message : getGraphQLErrorMsg(checkExistingUserNameError.message)});
        }
    }, [responseCheckExistingUserName, checkExistingUserNameError]);

    //전화번호 체크
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [requestVerificationCode, responseVerificationCode, verificationRequestError] = useRequestVerificationCode();
    const [requestCheckVerificationCode, responseCheckVerificationCode, checkVerificationRequestError] = useCheckVerificationCode();

    const handleOnClickRequestVerificationCode = () => {
        requestVerificationCode(phoneNumber);
    }

    const handleOnClickCheckVerificationCode = () => {
        requestCheckVerificationCode(phoneNumber, authNumber);
    }

    useEffect(() => {
        setIsPhoneVerified(true);

        if (responseVerificationCode) {
            setFocus('authNumber');
            clearErrors('phoneNumber');
            toast('인증번호가 발송되었습니다.');
        }
        if (verificationRequestError) {
            setError('phoneNumber', { message : getGraphQLErrorMsg(verificationRequestError.message)});
        }
    }, [responseVerificationCode, verificationRequestError]);

    useEffect(() => {
        if (responseCheckVerificationCode) {
            setIsPhoneVerified(true);
            toast('인증되었습니다.');
        }

        if (checkVerificationRequestError) {
            setIsPhoneVerified(false);

            setError('authNumber', { message : '인증번호가 올바르지 않습니다.'});
        }
    }, [responseCheckVerificationCode, checkVerificationRequestError]);

    useEffect(() => {
        if (isOverAge && agreeService && agreePrivacy && isAllowAd) {
            setValue('allCheck', true);
        } else if (!isOverAge || !agreeService || !agreePrivacy || !isAllowAd) {
            setValue('allCheck', false);
        }
    }, [isOverAge, agreeService, agreePrivacy, isAllowAd]);

    const handleOnClickAllCheck = () => {
        setValue('isOverAge', !allCheck);
        setValue('agreeService', !allCheck);
        setValue('agreePrivacy', !allCheck);
        setValue('isAllowAd', !allCheck);
    }

    const onSubmit = (data) => {
        submit(data);
    };

    return <MobileMaxWidthWrapper className={'max-width-400'}>
        <div className='SignUpForm-container'>
            <h1 className='page-title'>회원가입</h1>
            <div>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {!isSns && <div className='SignUpForm-email-container'>
                            <FormInput label="이메일" name="email" placeholder="예) fruits@fruitsfamily.com" rules={{ required: !isSns, pattern: EMAIL_VALIDATION
                                }}
                                onBlur={handleOnBlurEmail}
                            />
                        </div>}
                        <div className='SignUpForm-password-container'>
                            <FormInput label="닉네임" name="nickname" placeholder="예) fruitsfamily" rules={{ required: true, pattern: NICKNAME_VALIDATION
                                }}
                                onBlur={handleOnBlurUserName}
                            />
                        </div>
                        {!isSns && <div className='SignUpForm-password-container'>
                            <FormInput label="비밀번호" type="password" name="password" placeholder="영문, 숫자 조합 8~20자" rules={{ required: !isSns, pattern: PASSWORD_VALIDATION}} />
                        </div>}
                        <div className='SignUpForm-input-button-container'>
                            <div className='input'>
                                <FormInput label="휴대폰 번호" placeholder="휴대폰 번호" name="phoneNumber" rules={{ required: true }} maxLength={11} />
                            </div>
                            <div className={`${errors['phoneNumber'] && errors['phoneNumber'].message ? 'SignUpForm-button-error' : ''}`}>
                                <Button type={'button'} onClick={handleOnClickRequestVerificationCode} disabled={!phoneNumber} text={'인증번호 발송'} />
                            </div>
                        </div>
                        <div className='SignUpForm-input-button-container'>
                            <div className='input'>
                                <FormInput label="인증 번호" name="authNumber" placeholder="인증번호 6자리 입력" rules={{ required: true }} maxLength={6} />
                            </div>
                            <div className={`${errors['authNumber'] && errors['authNumber'].message ? 'SignUpForm-button-error' : ''}`}>
                                <Button type={'button'} onClick={handleOnClickCheckVerificationCode} disabled={!authNumber} text={'확인'} />
                            </div>
                        </div>
                        <div className='SignUpForm-agree-form-container'>
                            <div className='SignUpForm-all-agree-container'>
                                <div className='SignUpForm-all-agree-text' onClick={handleOnClickAllCheck}>전체 동의하기</div>
                                <FormCheckbox name={'allCheck'} register={register} onClick={handleOnClickAllCheck} required={false} />
                            </div>
                            <div className='SignUpForm-agree-container'>
                                <div className={`SignUpForm-agree-text ${isOverAge ? 'SignUpForm-select-text' : 'SignUpForm-unselect-text'}`} onClick={() => {
                                    setValue('isOverAge', !isOverAge);
                                }}>
                                    본인은 만 14세 이상입니다. (필수)
                                </div>
                                <FormCheckbox name={'isOverAge'} register={register} />
                            </div>
                            <div className='SignUpForm-agree-container'>
                                <div className={`SignUpForm-agree-text ${agreeService ? 'SignUpForm-select-text' : 'SignUpForm-unselect-text'}`} onClick={() => {
                                    setValue('agreeService', !agreeService);
                                }}>
                                    <a href="https://intercom.help/fruits-family-co/articles/3683933"
                                       rel="noopener noreferrer"
                                       target="_blank">서비스 이용약관</a>에 동의합니다. (필수)
                                </div>
                                <FormCheckbox name={'agreeService'} register={register} />
                            </div>
                            <div className='SignUpForm-agree-container'>
                                <div className={`SignUpForm-agree-text ${agreePrivacy ? 'SignUpForm-select-text' : 'SignUpForm-unselect-text'}`} onClick={() => {
                                    setValue('agreePrivacy', !agreePrivacy);
                                }}>
                                    <a href="https://intercom.help/fruits-family-co/articles/3683931"
                                       rel="noopener noreferrer"
                                       target="_blank">개인정보 취급방침</a>에 동의합니다. (필수)
                                </div>
                                <FormCheckbox name={'agreePrivacy'} register={register} />
                            </div>
                            <div className='SignUpForm-agree-container'>
                                <div className={`SignUpForm-agree-text ${isAllowAd ? 'SignUpForm-select-text' : 'SignUpForm-unselect-text'}`} onClick={() => {
                                    setValue('isAllowAd', !isAllowAd);
                                }}>
                                    <a href="https://intercom.help/fruits-family-co/articles/8339539"
                                       rel="noopener noreferrer"
                                       target="_blank">혜택/이벤트 정보 수신</a>에 동의합니다. (선택)
                                </div>
                                <FormCheckbox name={'isAllowAd'} register={register} required={false} />
                            </div>
                        </div>
                        <div className='SignUpForm-register-button-container'>
                            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid || !isPhoneVerified || !isEmailVerified || !isUserNameVerified} full={true} text={'회원가입'} />
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    </MobileMaxWidthWrapper>
}

SignUpForm.propTypes = {
    isSns : PropTypes.bool,
    submit: PropTypes.func,
    submitError: PropTypes.object
}
