import React, {useState} from 'react';
import PropTypes from "prop-types";
import { useFormContext } from 'react-hook-form';

import './FormInput.css';

/**
 * React Hook Form을 사용하는 input 컴포넌트
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormInput({ label, type, name, readOnly = false, placeholder, rules, maxLength, onBlur, onClick }) {
    const { register, formState: { errors } } = useFormContext();
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = () => {
        setIsFocused(false);
        if(onBlur) onBlur();
    };

    const handleClick = () => {
        if(onClick) onClick();
    }


    return (
        <div className='form-input-container'>
            <label>{label}</label>
            <div className={`input-container ${errors[name] ? 'error' : ''} ${isFocused ? 'focused' : ''}`}  onClick={handleClick}>
                <input {...register(name,rules)}
                       type={type}
                       placeholder={placeholder}
                       onFocus={() => setIsFocused(true)}
                       onBlur={handleBlur}
                       maxLength={maxLength}
                       readOnly={readOnly}
                />
            </div>
            {errors[name] && errors[name].message && <span className="error-message">{errors[name].message}</span>}
        </div>
    );
}

FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string,
    rules: PropTypes.object,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
}

