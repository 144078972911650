import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {collectionUrl, webappURL} from '../../util/url';

/**
 * Open Graph metadata component for a collection.
 * @param collection a collection for which to generate metadata
 * @param title meta title
 * @param hostname
 * @return {*}
 * @constructor
 */
export default function CollectionOpenGraph({ collection, title, hostname }) {
    return (
        <Helmet>
            {/* Cf schema at https://ogp.me/#type_article */}
            <meta property="fb:app_id" content="330702484245822" />
            <meta property="og:site_name" content="FruitsFamily" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={collection.description} />
            <meta property="og:url" content={webappURL(hostname) + collectionUrl(collection)} />
            <meta property="og:image" content={collection.resizedBigImage} />
            <meta property="article:published_time" content={collection.featured_from} />
            <meta property="article:modified_time" content={collection.updatedAt} />
            {collection.featured_until ? <meta property="article:expiration_time" content={collection.featured_until} /> : null}
            {collection.seller ? <meta property="article:author" content={collection.seller.user.nickname} /> : null}
        </Helmet>
    );
}
CollectionOpenGraph.propTypes = {
    collection: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        resizedBigImage: PropTypes.string.isRequired,
        featured_from: PropTypes.string.isRequired,
        featured_until: PropTypes.string,
        updatedAt: PropTypes.string.isRequired,
        seller: PropTypes.shape({
            user: PropTypes.shape({
                nickname: PropTypes.string.isRequired
            }).isRequired
        }),
    }).isRequired,
    title: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
};
