import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";

import Button from "../../../component/button/Button";
import MobileMaxWidthWrapper from "../../../component/mobileMaxWidthWrapper/MobileMaxWidthWrapper";
import KakaoLoginButton from "../../../component/kakaoLoginButton/KakaoLoginButton";
import FacebookLoginButton from "../../../component/facebookLoginButton/FacebookLoginButton";
import FormInput from "../../../component/rhf/formInput/FormInput";

import useLoginEmail from "../../../query/useLoginEmail";
import {signUpUrl} from "../../../util/url";
import {META_TAG_TITLE} from "../../../constants/constants";

import './Login.css';


/**
 * Login component
 * @returns {JSX.Element} Login component UI.
 */
export default function Login() {
    const methods = useForm({ mode: 'onChange'});
    const { handleSubmit, formState: { isValid }, setError } = methods;
    const [loginEmail, { called, data, loading, error }] = useLoginEmail();

    useEffect(() => {
        if (called && error) {
            setError('password', { message: '이메일 또는 비밀번호가 일치하지 않습니다.'});
            setError('email', {message: ''});
        }
    }, [called, data, loading, error]);

    const onSubmit = (data) => {
        handleLogin(data);
    };

    const handleLogin = (data) => {
        const loginEmailVariables = { variables: { email: data.email, password: data.password } };
        loginEmail(loginEmailVariables);
    };

    return <MobileMaxWidthWrapper className={'max-width-400'}>
        <Helmet>
            <title>로그인 | {META_TAG_TITLE}</title>
            <meta name="description"
                  content="유니크한 빈티지, 세컨핸드부터 구하기 힘든 하이엔드 디자이너 브랜드까지 후루츠에서 한번에." />
        </Helmet>
        <div className='Login-container'>
            <h1 className='page-title'>로그인</h1>
            <div>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='Login-email-container'>
                            <FormInput label="이메일" name="email" rules={{ required: true }} />
                        </div>
                        <div className='Login-password-container'>
                            <FormInput label="비밀번호" type="password" name="password" rules={{ required: true }} />
                        </div>
                        <div className='Login-button-container'>
                            <Button onClick={handleSubmit(onSubmit)} disabled={!isValid} full={true} text={'로그인'} />
                        </div>
                    </form>
                </FormProvider>
            </div>
            <div className='Login-other-page-container'>
                <Link to={signUpUrl()}>회원가입</Link>
            </div>
            <div className='Login-social-container'>
                <FacebookLoginButton />
                <KakaoLoginButton />
            </div>
        </div>
    </MobileMaxWidthWrapper>
}
