import React from 'react';
import PropTypes from "prop-types";

/**
 * ImageCaption component
 * @param url
 * @param aspectRatio
 * @param caption
 * @returns {JSX.Element}
 * @constructor
 */
export default function ImageCaption({url, aspectRatio = 1, caption}) {
    return <div style={{marginBottom: "3rem"}}>
        <img
            src={url}
            alt={caption}
            style={{width: "100%", height: `calc(100% / ${aspectRatio})`}}/>
        {caption &&
            <div style={{
                textAlign: "center",
                marginTop: "0.5rem",
                color: "#666666",
                letterSpacing: "0.00125rem",
                fontWeight: 100,
                fontSize: "0.875rem"
            }}>{caption}</div>
        }
    </div>
}

ImageCaption.propTypes = {
    url: PropTypes.string.isRequired,
    aspectRatio: PropTypes.number,
    caption: PropTypes.string,
}
