import React from 'react';
import PropTypes from 'prop-types';

import {searchMatchingSubstring} from "../../util/text";

import './MatchingText.css';

/**
 * This component is used to highlight the matching text in the search results.
 * @param fullText The full text to be displayed.
 * @param matchText The text to be highlighted.
 * @returns {JSX.Element}
 * @constructor
 */
export default function MatchingText({fullText, matchText}) {
    const match = searchMatchingSubstring(fullText, matchText);

    return (
        <>
            { match ? (
                <>
                    <span>{fullText.substring(0, match.startIndex)}</span>
                    <span className='MatchingText-bold'>{fullText.substring(match.startIndex, match.endIndex)}</span>
                    <span>{fullText.substring(match.endIndex)}</span>
                </>
            ) : fullText }
        </>
    );
}

MatchingText.propTypes = {
    fullText: PropTypes.string.isRequired,
    matchText: PropTypes.string.isRequired,
};
