import React, {useCallback, useState} from 'react';
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";

import searchImage from '../assets/icon_button_search.svg';
import {useSearchModalStore} from "../../../store/useSearchModalStore";
import {useSearchInputStore} from "../../../store/useSearchInputStore";
import {searchUrl} from "../../../util/url";
import {logSearch} from "../../../util/analytics";

import './SearchInputContainer.scss';

/**
 * Search input container
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchInputContainer() {
    const navigate = useNavigate();
    const { closeModal } = useSearchModalStore();
    const { searchQuery, setSearchQuery } = useSearchInputStore();
    const [isFocused, setIsFocused] = useState(false);
    const [searchValue, setSearchValue] = useState(searchQuery);

    const delayedUpdateQuery = useCallback(
        debounce((text) => {
            setSearchQuery(text);
        }, 300), []);

    const handleInputChange = ({ target: { value } }) => {
        setSearchValue(value);
        delayedUpdateQuery(value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleFormSubmit = (event) => {
        if (searchValue.length > 0) {
            logSearch(searchValue);
            navigate(searchUrl(searchValue));
        }
        closeModal();
        event.preventDefault();
    };

    return (
        <div className={`SearchInputContainer ${isFocused ? 'focused' : ''}`}>
            <div>
                <img alt={'search'} src={searchImage} className="image" />
            </div>
            <div className="SearchInputContainer-input-container">
                <form action={''} onSubmit={handleFormSubmit}>
                    <input
                        autoFocus={true}
                        type={'search'}
                        className="SearchInputContainer-search-input"
                        placeholder={'상품, 브랜드 및 유저 검색'}
                        value={searchValue}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </form>
            </div>
        </div>
    );
}
