import React from 'react';

import '../bootstrap/modal/Modal.css';
import './AppInstallModal.scss';
import {useAppInstallModal} from "../../store/useAppInstallModal";
import {isAndroid, isIOS} from "../../util/navigator";
import AppInstallModalMobile from "./AppInstallModalMobile";
import AppInstallModalPc from "./AppInstallModalPc";

/**
 * App 설치 유도 Modal
 * @returns {React.JSX.Element|null}
 * @constructor
 */
export default function AppInstallModal() {
    const { isOpen, text } = useAppInstallModal();
    const userAgent = window.navigator.userAgent;

    if (!isOpen) {
        document.body.style = `overflow: auto`
        return null;
    } else {
        document.body.style= `overflow: hidden`;
    }

    return (
        <dialog className="Modal modal fade show"
                tabIndex="-1" aria-hidden="true">
            <div className="AppInstallModal">
                {isIOS(userAgent) || isAndroid(userAgent) ?
                    <AppInstallModalMobile text={text}/> :
                    <AppInstallModalPc text={text}/>
                }
            </div>
        </dialog>
    )
}
