import React, {useEffect} from 'react';
import PropTypes from "prop-types";

import useCheckSnsCode from "../../../query/useCheckSnsCode";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuthStore} from "../../../store/useAuthStore";
import useSignInBySns from "../../../query/useSignInBySns";
import {homeUrl, signUpSns} from "../../../util/url";

/**
 * Social Login Complete Page
 * Social 로그인 완료 후 처리 페이지
 * @param socialType Social Type (KAKAO, FACEBOOK, APPLE)
 * @returns {JSX.Element}
 */
export default function SocialAuthComplete({socialType}) {
    const {login} = useAuthStore();
    const navigation = useNavigate();
    const [queryParameters] = useSearchParams();
    const [requestCheckSnsCode, responseCheckSnsCode, CheckSnsCodeError]  = useCheckSnsCode();
    const [requestSingInSns, responseSingInSns, singInSnsError] = useSignInBySns();

    //1. SNS 가입 여부 요청
    useEffect(() => {
        requestCheckSnsCode(socialType, queryParameters.get('code').toString());
    }, []);

    //2. SNS 가입 여부 요청 결과에 따른 처리
    useEffect(() => {
        if (responseCheckSnsCode) {
            //기 가입자 - 로그인 처리
            if (responseCheckSnsCode.checkSnsCode.isExistsAccount) {
                requestSingInSns(socialType, responseCheckSnsCode.checkSnsCode.access_token);
            } else {
                //미가입자 - 회원가입 페이지로 이동
                navigation(signUpSns(socialType, responseCheckSnsCode.checkSnsCode.access_token));
            }
        }
    }, [responseCheckSnsCode, CheckSnsCodeError]);

    //3. 로그인 처리 결과에 따른 처리
    useEffect(() => {
        if (responseSingInSns) {
            login(responseSingInSns.signinBySnsV2);
            navigation(homeUrl());
        }
    }, [responseSingInSns, singInSnsError]);

    return <div></div>
}

SocialAuthComplete.propTypes = {
    socialType: PropTypes.string.isRequired,
};
