import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

/**
 * Twitter card metadata component for a brand.
 * @param brand a brand for which to generate metadata
 * @param title meta title
 * @param description meta description
 * @return {*}
 * @constructor
 */
export default function BrandTwitterCard({ brand, title, description }) {
    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@fruitsfamilyco" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {brand.resizedBigLogo && (
                <meta name="twitter:image" content={brand.resizedBigLogo} />
            )}
            {brand.resizedBigLogo && (
                <meta name="twitter:image:alt" content={title} />
            )}
        </Helmet>
    );
}
BrandTwitterCard.propTypes = {
    brand: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        name_kr: PropTypes.string,
        description: PropTypes.string,
        resizedBigLogo: PropTypes.string
    }).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
