import React, {useEffect} from 'react';
import {friendInviteUrl, homeUrl, webappURL} from "../../util/url";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {useNavigate, useParams} from "react-router-dom";
import MobileMaxWidthWrapper from "../../component/mobileMaxWidthWrapper/MobileMaxWidthWrapper";
import './FriendInvite.css';

/**
 * 친구 초대 이벤트 페이지
 * @param hostname
 * @returns {Element}
 * @constructor
 */
export default function FriendInvite({hostname}) {
    const { code } = useParams();
    const navigate = useNavigate();

    const META_TAG_TITLE = '친구가 후루츠패밀리에 초대했어요.';
    const META_TAG_DESCRIPTION = `회원가입 화면에서 초대 코드 입력하고 가입 시 5,000원 쿠폰 지급!\nFruitsFamily 초대 코드: ${code}`;

    useEffect(() => {
        setTimeout(() => {
            alert('친구 초대 이벤트는 모바일 앱에서 참여 가능합니다.');
            navigate(homeUrl());
        }, 1000);
    }, []);

    return <section>
        <Helmet>
            <title>{META_TAG_TITLE}</title>
            <meta name="description"
                  content={META_TAG_DESCRIPTION} />
            <link rel="canonical" href={webappURL(hostname) + friendInviteUrl(code)}/>

            <meta property="og:title" content={META_TAG_TITLE}/>
            <meta property="og:description" content={META_TAG_DESCRIPTION} />
            <meta property="og:url" content={webappURL(hostname) + friendInviteUrl(code)}/>
            <meta property="og:image" content={`https://image.production.fruitsfamily.com/public/config/og-image-friend-invite-coupon.png`}/>
        </Helmet>
        <MobileMaxWidthWrapper>
            <img src={'https://image.production.fruitsfamily.com/public/config/og-image-friend-invite-coupon.png'}
                 className={'FriendInvite-img'} alt="친구 초대 이벤트"/>
        </MobileMaxWidthWrapper>
    </section>
}

FriendInvite.propTypes = {
    hostname: PropTypes.string.isRequired,
}
