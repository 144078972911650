import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

/**
 * Twitter card metadata component for a seller.
 * @param seller a seller for which to generate metadata
 * @param title meta title
 * @param description meta description
 * @return {*}
 * @constructor
 */
export default function SellerTwitterCard({ seller, title, description }) {
    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@fruitsfamilyco" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {seller.user.resizedBigImage ? <meta name="twitter:image" content={seller.user.resizedBigImage} /> : null}
            {seller.user.resizedBigImage ? <meta name="twitter:image:alt" content={title} /> : null}
        </Helmet>
    );
}
SellerTwitterCard.propTypes = {
    seller: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        user: PropTypes.shape({
            username: PropTypes.string.isRequired,
            nickname: PropTypes.string.isRequired,
            bio: PropTypes.string,
            resizedBigImage: PropTypes.string
        }).isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
