import {create} from "zustand";

/**
 * 배송 정보 등록
 */
export const useDeliveryAddress = create((set) => ({
    deliveryAddress: null,
    deliveryNote: '요청사항없음',

    setDeliveryAddress: function(deliveryAddress) {
        set({ deliveryAddress: deliveryAddress });
    },
    setDeliveryNote: function(deliveryNote) {
        set({ deliveryNote: deliveryNote });
    }
}));
