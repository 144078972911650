import React from 'react';

import './Loading.css';

export default function Loading() {
    return (
        <div className="Loading text-center">
            <div className="Loading-spinner spinner-border m-5" role="status">
                <span className="sr-only">로딩중...</span>
            </div>
        </div>
    );
}
