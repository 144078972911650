import React from 'react';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';

import {useAuthStore} from '../../store/useAuthStore';
import {homeUrl} from '../../util/url';
import './Settings.css'

/**
 * 마이페이지 내 설정 페이지
 * @returns {JSX.Element}
 */
export default function Settings() {
    const {logout} = useAuthStore();
    const navigate = useNavigate();

    const userLogout = () => {
        logout();
        navigate(homeUrl());
    }

    return (
        <section className="Settings container">
            <Helmet>
                <title>설정 | MY | 후루츠패밀리</title>
                <meta name="description" content={'후루츠패밀리에서 설정을 확인 및 변경할 수 있습니다.'} />
            </Helmet>

            <div className="Settings-title d-flex justify-content-center">
                <h1>설정</h1>
            </div>
            <div className="Settings-items d-flex flex-column align-items-center">
                <div className="Settings-item">
                    <a
                       href="https://intercom.help/fruits-family-co"
                       rel="noopener noreferrer"
                       target="_blank">
                        고객센터
                    </a>
                </div>
                <div className="Settings-item">
                    <span onClick={() => {
                        window.Intercom('showMessages');
                    }}>
                        1:1 문의
                    </span>
                </div>
                <div className="Settings-item">
                    <span onClick={userLogout}>로그아웃</span>
                </div>
            </div>
        </section>
    );
}