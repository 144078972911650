import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

import Empty from '../../../component/empty/Empty';
import Error from '../../../component/error/Error';
import Loading from '../../../component/loading/Loading';
import Rating from '../../../component/rating/Rating';
import ProductPreview from '../../../component/productPreview/ProductPreview';
import UserPreview from '../../../component/userPreview/UserPreview';
import {useAuthStore} from '../../../store/useAuthStore';
import {dateFormat} from '../../../util/locale';
import {myPageUrl, sellerUrl} from '../../../util/url';

import './Review.css';

// GraphQL queries
const SEE_USER_REVIEWS = gql`
    query SeeUserReviews($sellerId: Int!, $offset: Int) {
        seeUserReviews(id: $sellerId, offset: $offset) {
            id
            text
            rating
            createdAt
            user {
                # TODO use USER_FRAGMENT
                id
                username
                nickname
                resizedSmallImage
            }
            product {
                # TODO use ProductFragment
                # TODO use ProductDetailsPreloadFragment
                id
                createdAt
                category
                title
                description
                brand
                price
                status
                external_url
                resizedSmallImages
            }
        }
    }
`;

/**
 * Seller review tab component.
 */
export default function Review({ seller }) {
    const store = useAuthStore();
    // fetch the comments list
    const { loading, error, data } = useQuery(
        SEE_USER_REVIEWS,
        {
            variables: {
                offset: 0, // TODO implement load more
                sellerId: parseInt(seller.id, 10)
            },
            fetchPolicy: 'cache-and-network' // force cache refresh to show new reviews
        }
    );

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    // build the UI elements
    const renderReview = review => (
        <li className="Review-item media" key={review.id}>
            <div className="Review-item-body media-body">
                <h6 className="Review-item-reviewer">
                    <Link to={store.user && store.user.id === review.user.id ? myPageUrl() : sellerUrl(review.user)}>
                        <span className="Review-item-reviewer-image">
                                <UserPreview user={review.user} />
                        </span>
                        <span className="Review-item-reviewer-nickname font-nhaasgrotesk">
                            {review.user.nickname}
                        </span>
                    </Link>
                </h6>
                <div className="Review-item-rating">
                    <Rating rating={review.rating} />
                </div>
                <div className="Review-item-date">
                    {dateFormat(new Date(review.createdAt))}
                </div>
                <p className="Review-item-text">
                    {review.text}
                </p>
            </div>
            <div className="Review-item-product ml-3">
                <ProductPreview product={review.product} />
            </div>
        </li>
    );
    const reviewItems = data.seeUserReviews.length ?
        data.seeUserReviews.map(renderReview):
        (
            <li className="Review-empty">
                <Empty>
                    구매후기가 없습니다
                </Empty>
            </li>
        );

    return (
        <section className="Review">
            <ul className="Review-list list-unstyled">
                {reviewItems}
            </ul>
        </section>
    );
}
Review.propTypes = {
    seller: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired
};

