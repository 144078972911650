import React from 'react';
import PropTypes from 'prop-types';

import './Empty.css';

export default function Empty({children}) {
    return (
        <div className="Empty text-center m-5">
            {children || '결과가 없습니다'}
        </div>
    );
}
Empty.propTypes = {
    children: PropTypes.node
};
