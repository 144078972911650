import React from 'react';
import PropTypes from 'prop-types';
import {gql} from 'apollo-boost';
import {Helmet} from 'react-helmet';

import {PRODUCT_FRAGMENT} from '../../../component/productPreview/ProductPreview';
import ProductsList from '../../../component/productsList/ProductsList';
import {sellerUrl} from '../../../util/url';

import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from "../../product/Product";

import './Shop.css';

// GraphQL queries
const SEE_SELLER_PRODUCTS = gql`
    query SeeSellerProducts($filter: ProductFilter!, $offset: Int!, $limit: Int!) {
        searchProducts(filter: $filter, offset: $offset, limit: $limit, origin: "SELLER") {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
            price
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Seller shop tab component.
 */
export default function Shop({ seller }) {
    return (
        <section className="Shop">
            <Helmet>
                <link rel="alternate" type="application/atom+xml" href={`${sellerUrl(seller)}/feed.atom`} />
            </Helmet>
            <ProductsList itemsProperty="searchProducts"
                          query={SEE_SELLER_PRODUCTS}
                          variables={{filter: {
                                  sellerId: parseInt(seller.id, 10),
                          }}} />
        </section>
    );
}
Shop.propTypes = {
    seller: PropTypes.shape({
        id:  PropTypes.string.isRequired
    }).isRequired
};

