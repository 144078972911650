import React from 'react';
import PropTypes from "prop-types";
import "./SellerReferralCode.css";
import {useAppStoreUrl, useGooglePlayUrl} from "../../hook/appUrl";
import {isAndroid, isIOS} from "../../util/navigator";

export default function SellerReferralCode({code}) {
    const userAgent = window.navigator.userAgent;
    const appStoreUrl = useAppStoreUrl();
    const googlePlayUrl = useGooglePlayUrl();

    const handleOnClick = () => {
        // 초대 코드 복사
        navigator.clipboard.writeText(code).then(() => {
            alert('추천인 코드가 복사되었습니다.\n' + '앱 다운로드 > 회원가입 시 붙여 넣어주세요!');
        }).catch(err => {
            console.error('추천인 코드 복사에 실패하였습니다.:', err);
        }).finally(() => {
            if (isIOS(userAgent)) {
                window.location.href = appStoreUrl;
            } else if (isAndroid(userAgent)) {
                window.location.href = googlePlayUrl;
            } else {
                window.location.href = appStoreUrl;
            }
        });


    }

    return <div className="SellerReferralCode-container" onClick={handleOnClick}>
        <div className='SellerReferralCode-title'>5,000원 할인 추천인 코드</div>
        <div className='SellerReferralCode-code'>
            <span>{code}</span>
            <span className='SellerReferralCode-copy'>복사</span>
        </div>
    </div>
}

SellerReferralCode.propTypes = {
    code: PropTypes.string.isRequired
}
