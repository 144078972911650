import React from 'react';
import PropTypes from 'prop-types';
import AppOpenButton from "./AppOpenButton";
import './AppMobileBanner.css';
import fruitsfamilyLogo from "../appTopBanner/assets/rounded_logo.svg";
import iconStar from "../appTopBanner/assets/ic_black_star.svg";

export default function AppMobileBanner({hostname, userAgent}) {
    return (
        <div className="Mobile-banner-container Navigation-navbar navbar navbar-expand-md">
            <div className='container'>
                <div className='Mobile-banner'>
                    <img className="Logo" alt="FruitsFamily™" src={fruitsfamilyLogo} />
                    <div className='Content'>
                        <div className='Title'>패션 러버들의 중고거래 앱, 후루츠</div>
                        <div className='Description'>
                            <div>
                                <img src={iconStar} alt="balck-star" />
                                <img src={iconStar} alt="balck-star" />
                                <img src={iconStar} alt="balck-star" />
                                <img src={iconStar} alt="balck-star" />
                                <img src={iconStar} alt="balck-star" />
                            </div>
                            <div className='Text'>(1.6k)</div>
                        </div>
                    </div>
                </div>
                <div>
                    <AppOpenButton hostname={hostname} userAgent={userAgent} />
                </div>
            </div>
        </div>
    );
}

AppMobileBanner.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired,
};