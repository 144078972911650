import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import './InfiniteScroll.css';

/**
 * Infinite scroll component.
 */
export default function InfiniteScroll({ children, hasMore, loading, loadMore }) {
    const [loadMoreButtonRef, setLoadMoreButtonRef] = useState(null);
    useEffect(() => {
        if (loadMoreButtonRef) {
            const intersectionObserver = new IntersectionObserver(
                ([intersectionObserverEntry]) => {
                    if (intersectionObserverEntry.isIntersecting && !loadMoreButtonRef.disabled) {
                        loadMoreButtonRef.click();
                    }
                },
                {
                    rootMargin: '100%' // start loading more 1 viewport before reaching the intersection
                }
            );
            intersectionObserver.observe(loadMoreButtonRef);

            return () => {
                intersectionObserver.disconnect();
            };
        } else {
            return;
        }
    }, [loadMoreButtonRef]);

    // build the UI elements
    const loadMoreButton = hasMore ?
        (
            <button type="button" className="InfiniteScroll-more btn btn-secondary d-block mx-auto"
                    ref={setLoadMoreButtonRef}
                    disabled={loading}
                    onClick={loadMore}>
                {loading ?
                    <React.Fragment>
                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        로딩중...
                    </React.Fragment> :
                    <React.Fragment>
                        더보기
                    </React.Fragment>
                }
            </button>
        ) :
        null;

    return (
        <div className="InfiniteScroll">
            {children}
            {loadMoreButton}
        </div>
    );
}
InfiniteScroll.propTypes = {
    children: PropTypes.node.isRequired,
    hasMore: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired
};
