import 'react-app-polyfill/stable';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from '@apollo/react-hooks';

import {apolloClient} from './apollo';
import Head from './component/head/Head';
import Footer from './component/footer/Footer';
import Navigation from './component/navigation/Navigation';
import StagingRibbon from './component/stagingRibbon/StagingRibbon';
import AutoLogin from "./component/autoLogin/AutoLogin";
import SearchModal from "./page/search/searchModal/SearchModal";
import AppInstallModal from "./component/appInstallModal/AppInstallModal";
import {launchAppStore} from './util/appLink';
import CustomToast from "./component/toast/CustomToast";

import './App.css';
import AppRoutes from "./component/routes/AppRoutes";

export default function App() {
    // redirect to the app store if requested by a location url parameter
    // run only once during the app initialization
    useEffect(() => {
        launchAppStore(window.history, window.location, window.navigator.userAgent);
    }, []);

    return (
        <ApolloProvider client={apolloClient}>
            <Head hostname={window.location.hostname}/>
            <BrowserRouter>
                <Navigation hostname={window.location.hostname} userAgent={window.navigator.userAgent} />
                <div className="App-content">
                    <AppRoutes hostname={window.location.hostname}
                               userAgent={window.navigator.userAgent} />
                </div>
                <Footer />
                {/* components which are not present in App.ssr should be last */}
                <StagingRibbon hostname={window.location.hostname} />
                <SearchModal hostname={window.location.hostname} userAgent={window.navigator.userAgent}/>
                <AppInstallModal />
                <AutoLogin />
                <CustomToast />
            </BrowserRouter>
        </ApolloProvider>
    );
}
