import React from "react";
import PropTypes from "prop-types";
import './MobileMaxWidthWrapper.css';

export default function MobileMaxWidthWrapper({children, className = ''}) {
    return (
        <div className={`mobile-max-width-wrapper ${className}`}>
            {children}
        </div>
    );
}

MobileMaxWidthWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
