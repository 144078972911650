import React from 'react';
import PropTypes from 'prop-types';
import {Link, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';
import {Helmet} from 'react-helmet';

import Error from '../../component/error/Error';
import Loading from '../../component/loading/Loading';
import {SCHEMA_CONTEXT, sellerSchemaOrg} from '../../util/schemaOrg';
import {seoURIComponent} from '../../util/text';
import {sellerUrl, webappURL} from '../../util/url';

import Like from './like/Like';
import Profile from './profile/Profile';
import Review from './review/Review';
import Shop from './shop/Shop';
import SellerOpenGraph from './SellerOpenGraph';
import SellerTwitterCard from './SellerTwitterCard';

import './Seller.css';
import {isWithinInt32Range} from "../../util/util";

export const SELLER_TAB = {
    SHOP: Symbol('SHOP'),
    LIKE: Symbol('LIKE'),
    REVIEW: Symbol('REVIEW')
};

// GraphQL queries
const SEE_USER = gql`
    query SeeUser($userId: Int!) {
        seeUser(id: $userId) {
            id
            username
            nickname
            bio
            resizedBigImage
            follower_count
            following_count
            seller {
                id
                rating
                review_count
            }
        }
    }
`;

/**
 * Return a Korean description for a seller page tab.
 * @param {Symbol} sellerTab the tab on the seller page
 * @returns a description in Korean
 */
export function getSellerTabToKorean(sellerTab) {
    switch (sellerTab) {
        case SELLER_TAB.SHOP:
            return {
                title :'의 Shop',
                description: '의 상품을 후루츠에서 거래하세요.'
            }
        case SELLER_TAB.LIKE:
            return {
                title :'의 위시리스트',
                description: '의 후루츠 위시리스트를 구경해 보세요.'
            }
        case SELLER_TAB.REVIEW:
            return {
                title :' 상점의 리뷰',
                description: ' 상점에 남겨진 리뷰를 확인해 보세요.'
            }
        default:
            throw new Error('Unknown seller tab type.');
    }
}

/**
 * Seller page component.
 */
export default function Seller({ hostname, userAgent }) {
    // fetch the seller details
    const {
        sellerId: encodedSellerId,
        sellerTab: encodedSellerTab = seoURIComponent(SELLER_TAB.SHOP.description) // default parameter value
    } = useParams();
    const sellerId = parseInt(encodedSellerId, 36);

    if (!isWithinInt32Range(sellerId)) {
        return <div className="Error alert alert-danger" role="alert">잘못된 접근입니다.</div>
    }

    const sellerTab = Object.values(SELLER_TAB)
        .find(symbol => encodedSellerTab === seoURIComponent(symbol.description));

    // fetch seller details
    const {loading, error, data} = useQuery(
        SEE_USER,
        {
            variables: {
                userId: sellerId
            }
        }
    );

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    const seller = {
        ...data.seeUser.seller,
        reviews: new Array(data.seeUser.seller.review_count), // FIXME missing reviews field from SeeUser() api
        user: Object.fromEntries(
            Object.entries(data.seeUser)
                .filter(([key]) => key !== 'seller')
        )
    };

    // build the UI elements
    const tabContent = (() => {
        switch (sellerTab) {
            case SELLER_TAB.SHOP:
                return <Shop seller={seller} />;
            case SELLER_TAB.LIKE:
                return <Like seller={seller} />;
            case SELLER_TAB.REVIEW:
                return <Review seller={seller} />;
            default:
                throw new Error('Unknown seller tab type.');
        }
    })();

    // meta tag
    const metaTitle = `${seller.user.nickname}(@${seller.user.username})${getSellerTabToKorean(sellerTab).title} | 후루츠패밀리`;
    const metaDescription = (seller.user.bio ? `${seller.user.bio.trim()} | ` : '') + `${seller.user.username}${getSellerTabToKorean(sellerTab).description}`

    return (
        <section className="Seller container">
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={webappURL(hostname) + sellerUrl(seller, sellerTab)} />
                <script type="application/ld+json">{JSON.stringify(sellerSchemaOrg(seller, SCHEMA_CONTEXT))}</script>
            </Helmet>
            <SellerOpenGraph seller={seller} sellerTab={sellerTab} title={metaTitle} description={metaDescription} hostname={hostname}/>
            <SellerTwitterCard seller={seller} sellerTab={sellerTab} title={metaTitle} description={metaDescription} />

            <div className="Seller-details">
                <Profile hostname={hostname}
                         seller={seller}
                         userAgent={userAgent} />
            </div>

            <nav className="Seller-nav sticky-top">
                <ul className="Seller-nav-underline nav nav-underline">
                    <Link className={`Seller-nav-link nav-item nav-link ${sellerTab === SELLER_TAB.SHOP ? 'active' : ''}`}
                          to={sellerUrl(seller, SELLER_TAB.SHOP)}
                          replace={true}>
                        Shop
                    </Link>
                    <Link className={`Seller-nav-link nav-item nav-link ${sellerTab === SELLER_TAB.LIKE ? 'active' : ''}`}
                          to={sellerUrl(seller, SELLER_TAB.LIKE)}
                          replace={true}>
                        Wishlist
                    </Link>
                    <Link className={`Seller-nav-link nav-item nav-link ${sellerTab === SELLER_TAB.REVIEW ? 'active' : ''}`}
                          to={sellerUrl(seller, SELLER_TAB.REVIEW)}
                          replace={true}>
                        Reviews
                    </Link>
                    <div className="nav-slider"></div>
                </ul>
            </nav>

            {tabContent}
        </section>
    );
}
Seller.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
