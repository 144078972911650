import secureLocalStorage  from  "react-secure-storage";

export function setAuthKey(access, refresh) {
    secureLocalStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN_NAME, access);
    secureLocalStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_NAME, refresh);
}

export function clearAuthKey() {
    secureLocalStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN_NAME);
    secureLocalStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_NAME);
}

export function setAccessKey(access) {
    secureLocalStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN_NAME, access);
}

export function setRefreshKey(refresh) {
    secureLocalStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_NAME, refresh);
}

export function getAccessKey() {
    return secureLocalStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME);
}

export function getRefreshKey() {
    return secureLocalStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME);
}
