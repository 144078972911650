import React from 'react';
import {Link} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

import Loading from '../../../component/loading/Loading';
import {brandUrl} from '../../../util/url';

import './PopularBrands.css';

// GraphQL queries
const POPULAR_BRANDS = gql`
    {
      getPopularKeywordsByFilterCached {
        popularBrands {
            name
        }
      }
    }
`;

/**
 * Popular brands component.
 */
export default function PopularBrands() {
    const { loading, data } = useQuery(
        POPULAR_BRANDS
    );

    // build the UI elements
    const items = (data && data.getPopularKeywordsByFilterCached && data.getPopularKeywordsByFilterCached.popularBrands ? data.getPopularKeywordsByFilterCached.popularBrands : []).map(brand => (
        <li key={brand.name}>
            <Link className="PopularBrands-link"
                  to={brandUrl(brand.name)}>
                <h2 className="PopularBrands-text">{brand.name}</h2>
            </Link>
        </li>
    ));

    return (
        <ul className="PopularBrands list-unstyled">
            {loading ?
                (
                    <li className="PopularBrands-loading">
                        <Loading />
                    </li>
                ) :
                items
            }
        </ul>
    );
}
PopularBrands.propTypes = {};
