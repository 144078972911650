import {useMemo} from "react";
import { useQuery } from '@apollo/react-hooks';
import {gql} from 'apollo-boost';
import {searchNormalize} from "../util/text";

const ALL_BRANDS = gql`
    {
        searchSuggestions: getBrandsV2Cached(filter: {havingProducts: true}, limit: 100000) {
            id
            name
            name_kr
        }
    }
`;

/**
 * Fetch search suggestions.
 * @param searchQuery the search query
 * @return {*} the list of suggestions
 */
export default function useSearchSuggestion(searchQuery) {
    const allBrands = useQuery(
        ALL_BRANDS,
        {
            fetchPolicy: 'cache-first',
        }
    );

    // filter suggestions client side for now, for reduced latency
    const searchQueryNormalized = searchQuery ? searchNormalize(searchQuery) : undefined;
    const brandsIndex = useMemo(() => (
        allBrands.data ?
            Object.fromEntries(
                allBrands.data.searchSuggestions
                    .flatMap(brand => ([
                        [searchNormalize(brand.name), brand],
                        brand.name_kr ? [searchNormalize(brand.name_kr), brand] : undefined
                    ]))
                    .filter(entries => entries)
            ) :
            undefined
    ), [allBrands.data]);

    const suggestions = useMemo(() => (
        brandsIndex && searchQueryNormalized ?
            {
                ...allBrands,
                data: {
                    searchSuggestions: Array.from(new Set(
                        Object.entries(brandsIndex)
                            .filter(
                                ([nameNormalized]) => nameNormalized.includes(searchQueryNormalized)
                            )
                            .slice(0, 10)
                            .map(([, brand]) => brand)
                    ))
                }
            } :
            undefined
    ), [allBrands, brandsIndex, searchQueryNormalized]);

    // return search results matching the query context
    return searchQuery && suggestions && suggestions.data ?
        {...suggestions, type: 'SEARCH'} : {};
}
