import {gql} from 'apollo-boost';
import {useLazyQuery} from "@apollo/react-hooks";

export const GET_CHECK_EXIST_USERNAME = gql`
  query getCheckExistingUserName($userName: String) {
    isExistingUser(username: $userName)
  }
`;

export default function useCheckExistingUserName() {
    const [requestCheckExistingUserName, {data, error}] = useLazyQuery(GET_CHECK_EXIST_USERNAME, {
        fetchPolicy: 'network-only',
    });

    const executeQuery = (userName) => {
        requestCheckExistingUserName({ variables: { userName } });
    };

    return [executeQuery, data, error];
}
