import {gql} from 'apollo-boost';
import {useLazyQuery} from "@apollo/react-hooks";

const GET_REQUEST_VERIFICATION_CODE = gql`
  query requestIdentificationV2($phone: String!) {
    requestIdentificationV2(phone: $phone)
  }
`;

export default function useRequestVerificationCode() {
    const [requestVerificationCode, {data, error}] = useLazyQuery(GET_REQUEST_VERIFICATION_CODE, {
        fetchPolicy: 'network-only',
    });

    const executeQuery = (phone) => {
        requestVerificationCode({ variables: { phone } });
    };

    return [executeQuery, data, error];
}
