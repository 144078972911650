/**
 * Format a price for the current user locale.
 * @param number a Number or BigInt to format
 * @return {string} the formatted string
 */
export function currencyFormat(number, currency) {
    return currency === 'KRW' ?
        // specialized formatting to match standard korean won format
        new Intl.NumberFormat(
            'ko-KR',
            {
                style: 'currency',
                currency: currency
            }
        ).format(number).replace(/[^0-9,.]*([0-9,.]+)[^0-9,.]*/, '$1원') :
        // generic currency formatting
        new Intl.NumberFormat(
        undefined,
            {
                style: 'currency',
                currency: currency
            }
        ).format(number);
}

/**
 * Format a date for the current user locale.
 * @param date the date to format
 * @param dateStyle the date style, defaults to medium
 * @param timeStyle the time style, default to hidden
 * @return {string} the formatted string
 */
export function dateFormat(date, dateStyle = 'medium', timeStyle = undefined) {
    return new Intl.DateTimeFormat(
        undefined,
        {
            dateStyle: dateStyle,
            timeStyle: timeStyle
        }
    ).format(date);
}

/**
 * Format a compact number for the current user locale.
 * @param number a Number or BigInt to format
 * @return {string} the formatted string
 */
export function numberCompactFormat(number) {
    return new Intl.NumberFormat(
        undefined,
        {
            notation: 'compact'
        }
    ).format(number);
}

/**
 * Format a relative time for the current user locale.
 * @param date the date to format
 * @return {string} the formatted string
 */
export function timeagoFormat(date) {
    const relative_time = date.getTime() - Date.now();
    const relative_units = [
        {name: 'year', duration: 365 * 24 * 60 * 60 * 1000},
        {name: 'month', duration: 30 * 24 * 60 * 60 * 1000},
        {name: 'week', duration: 7 * 24 * 60 * 60 * 1000},
        {name: 'day', duration: 24 * 60 * 60 * 1000},
        {name: 'hour', duration: 60 * 60 * 1000},
        {name: 'minute', duration: 60 * 1000},
        {name: 'second', duration: 1000}
    ];

    const largest_unit = relative_units.find(unit => Math.abs(relative_time) > unit.duration) ||
        relative_units[relative_units.length - 1];

    return new Intl.RelativeTimeFormat()
        .format(Math.round(relative_time / largest_unit.duration), largest_unit.name);
}
