import React from 'react';
import imgInvisible from './img_invisible.png';

import './InvisibleProduct.css';
import {Link} from "react-router-dom";

/**
 * 숨김 상품 UI
 * @returns {JSX.Element}
 * @constructor
 */
export default function InvisibleProduct() {
    return (
        <div className='InvisibleProduct-container'>
            <img className='InvisibleProduct-img' src={imgInvisible} alt="숨김 처리된 상품입니다." />
            <div className='InvisibleProduct-title'>숨김 처리된 상품입니다.</div>
            <Link to={'/collection/1'} className='InvisibleProduct-link'>
                {'후루츠 인기 매물 구경하기 >'}
            </Link>
        </div>
    );
}
