import {create} from "zustand";
import {loginUrl} from "../util/url";
import {clearAuthKey, setAccessKey, setRefreshKey} from "../util/secureAuthStorage";
import {logLogin} from "../util/analytics";
import {redirectAfterLogin} from "../util/util";

export const useAuthStore = create((set) => ({
    user: null,
    isLogin: false,
    redirectTo: null,

    /**
     * @param {Object} user - The User object
     */
    setUser: function(user) {
        set({ user: user });
    },
    login: async function (user) {
        if (user.access_token) setAccessKey(user.access_token);
        if (user.refresh_token) setRefreshKey(user.refresh_token);
        set({ isLogin: true, user : user, redirectTo: redirectAfterLogin() });

        //Intercom Login
        setTimeout(() => {
            window.Intercom('update', {
                name: user.username,
                email: user.email,
                user_id: user.id,
                hide_default_launcher: false
            });
        }, 300);

        await logLogin(user.login_type);
    },
    logout: async function () {
        clearAuthKey();
        set({ isLogin: false, user: null, redirectTo: loginUrl() });

        //Intercom Login
        window.Intercom('update', {
            name: '',
            email: '',
            user_id: '',
            hide_default_launcher: true
        });
    },
    autoLogin: async () => {

    }
}));
