import React from 'react';
import PropTypes from 'prop-types';

import './SimilarProductList.css';
import {Link} from "react-router-dom";
import {productUrl} from "../../util/url";
import {useAppInstallModal} from "../../store/useAppInstallModal";
import {openAppAndAction} from "../../util/util";

export default function SimilarProductList({products, limit}) {
    const { openModal } = useAppInstallModal();
    const handleOnClickPopup = () => {
        openAppAndAction(() => openModal('앱에서 더 많은\n상품을 비교해보세요!'), null, 1000);
    };

    return (
        <div className='SimilarProductList-item-container'>
            {products.map((product) => {
                return <Link className={"SimilarProductList-item"} key={product.id} to={productUrl(product)}>
                    <img className={"SimilarProductList-item-image"} src={product.resizedSmallImages[0]} alt="상품 이미지"/>
                </Link>
            })}
            {products.length === limit &&
                <a onClick={handleOnClickPopup} className={"SimilarProductList-moreButton"}>더보기</a>
            }
        </div>
    )
}

SimilarProductList.propTypes = {
    products: PropTypes.array.isRequired,
    limit: PropTypes.number.isRequired
}
