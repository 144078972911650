import React from 'react';
import PropTypes from 'prop-types';
import {gql} from 'apollo-boost';

import './UserPreview.css';

// GraphQL queries
export const USER_FRAGMENT = gql`
    fragment UserFragment on UserNotMe {
        nickname
        resizedSmallImage
    }
`;

export default function UserPreview({ user }) {
    const userInitials = user.nickname.slice(0, 2);

    // build the UI elements
    const image = user.resizedSmallImage ?
        (
            <img className="UserPreview-image rounded-circle"
                 loading="lazy"
                 alt=""
                 src={user.resizedSmallImage} />
        ) :
        (
            <span className="UserPreview-image rounded-circle UserPreview-image--missing"
                  style={{
                      fontSize: `${0.6 / Math.sqrt(userInitials.length)}em`
                  }}>
                {userInitials}
            </span>
        );

    return (
        <div className="UserPreview">
            {image}
        </div>
    );
}
UserPreview.propTypes = {
    user: PropTypes.shape({
        nickname: PropTypes.string.isRequired,
        resizedSmallImage: PropTypes.string
    }).isRequired
};
