import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

import Loading from '../../../component/loading/Loading';
import {isMobile} from '../../../util/navigator';
import {collectionUrl} from '../../../util/url';

import './DiscoveryCollectionsList.css';

const WE_LOVE = 'We Love';
const PLACEHOLDER_DATA = [
    {
        id: '-1',
        title: '\xa0',
        subtitle: '\xa0',
        resizedSmallImage: '',
        resizedBigImage: ''
    },
    {
        id: '-1',
        title: '\xa0',
        subtitle: '\xa0',
        resizedSmallImage: '',
        resizedBigImage: ''
    }
];

// GraphQL queries
const GET_COLLECTIONS = gql`
    {
        getCollectionsCached {
            id
            title
            subtitle
            resizedSmallImage
            resizedBigImage
        }
    }
`;

// Collection item sub component
const CollectionItem = ({collection, smallImage, titleClass, subTitleClass}) => (
    <>
        <div className="DiscoveryCollectionsList-49by40">
            <div className="DiscoveryCollectionsList-49by40-content">
                <Link className="DiscoveryCollectionsList-link"
                      to={collectionUrl(collection)}>
                    <img className="DiscoveryCollectionsList-image"
                         alt={`${collection.title} - ${collection.subtitle}`}
                         src={smallImage ? collection.resizedSmallImage : collection.resizedBigImage} />
                </Link>
            </div>
        </div>
        <h3 className={`DiscoveryCollectionsList-title ${titleClass}`}>
            {collection.title}
        </h3>
        <div className={`DiscoveryCollectionsList-subtitle ${subTitleClass}`}>
            {collection.subtitle}
        </div>
    </>
);
CollectionItem.propTypes = {
    collection: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        resizedSmallImage: PropTypes.string.isRequired,
        resizedBigImage: PropTypes.string.isRequired
    }).isRequired,
    smallImage: PropTypes.bool.isRequired,
    titleClass: PropTypes.string.isRequired,
    subTitleClass: PropTypes.string.isRequired
};

/**
 * Discovery collections list component.
 */
export default function DiscoveryCollectionsList({ userAgent }) {
    const { loading, data } = useQuery(
        GET_COLLECTIONS,
        {
            fetchPolicy: 'cache-and-network'
        }
    );
    const filteredCollections = useMemo(
        () => data && data.getCollectionsCached ?
            data.getCollectionsCached.filter(collection => collection.title !== WE_LOVE) :
            PLACEHOLDER_DATA,
        [data]
    );

    // build the UI elements
    return (
        <div className="DiscoveryCollectionsList">
            {loading && !data && (
                <div className="DiscoveryCollectionsList-loading">
                    <Loading />
                </div>
            )}
            <div className={`DiscoveryCollectionsList-large ${loading && !data ? 'DiscoveryCollectionsList-large--loading' : ''}`}>
                <CollectionItem collection={filteredCollections[0]}
                                smallImage={false}
                                titleClass="DiscoveryCollectionsList-title--large DiscoveryCollectionsList-title--first"
                                subTitleClass="DiscoveryCollectionsList-subtitle--large DiscoveryCollectionsList-subtitle--first" />
            </div>
            {filteredCollections.length > 3 && (
                <ul className={`DiscoveryCollectionsList-list list-inline row row-cols-1 row-cols-md-2 row-cols-xl-3 ${isMobile(userAgent) ? 'DiscoveryCollectionsList-list--mobile' : ''} ${loading && !data ? 'DiscoveryCollectionsList-list--loading' : ''}`}>
                    {filteredCollections.slice(1).map((collection, index) => (
                        <li className="DiscoveryCollectionsList-item col"
                            key={index}>
                            <CollectionItem collection={collection}
                                            smallImage={true}
                                            titleClass={index === 0 ? 'DiscoveryCollectionsList-title--first' : ''}
                                            subTitleClass={index === 0 ? 'DiscoveryCollectionsList-subtitle--first' : ''} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
DiscoveryCollectionsList.propTypes = {
    userAgent: PropTypes.string.isRequired
};
