import React, { useState } from "react";
import PropTypes from 'prop-types';
import "./Accordion.css";

import iconPlus from './assets/ic_plus.svg';
import iconMinus from './assets/ic_minus.svg';

/**
 * Accordion 컴포넌트
 * @param title
 * @param content
 * @returns {Element}
 * @constructor
 */
export function Accordion({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    const contentClass = isOpen ? 'accordion-content' : 'accordion-content accordion-content-hidden';

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={toggleOpen}>
                <div>{title}</div>
                {isOpen ?
                    <img src={iconMinus} alt={'닫기'} className="accordion-img"/> :
                    <img src={iconPlus} alt={'열기'} className="accordion-img"/>
                }
            </div>
            <div className={contentClass}>{content}</div>
        </div>
    );
}

Accordion.propTypes = {
    title: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
}
