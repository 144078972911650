import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {useSearchInputStore} from "../../../store/useSearchInputStore";
import {useSearchModalStore} from "../../../store/useSearchModalStore";
import useSearchUser from "../../../query/useSearchUser";
import {sellerUrl} from "../../../util/url";
import FollowButton from "../../../component/followButton/FollowButton";

import './SearchUserList.scss';
import UserPreview from "../../../component/userPreview/UserPreview";

/**
 * Search user list
 * @param hostname
 * @param userAgent
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchUserList({hostname, userAgent}) {
    const { searchQuery, initSearchQuery } = useSearchInputStore();
    const { closeModal } = useSearchModalStore();
    const navigate = useNavigate();

    const {
        data: suggestionsData,
    } = useSearchUser(searchQuery);

    const handleOnClick = (seller) => {
        initSearchQuery();
        navigate(sellerUrl(seller));
        closeModal();
    }

    return <>
            {searchQuery.length > 0 && suggestionsData && suggestionsData.searchUsers.length > 0 &&
                <div>
                    <div className='SearchUserList-title'>
                        Members
                    </div>
                    {suggestionsData.searchUsers.map((user) => (
                        <div className='SearchUserList-card' key={user.id} onClick={() => {
                            handleOnClick(user);
                        }}>
                            <div className='SearchUserList-card-content'>
                                <div className='profile'>
                                    <div className='image'>
                                        <UserPreview user={user} />
                                    </div>
                                    <div className='SearchBrandList-card-name'>
                                        <div className='name'>{user.nickname}</div>
                                        <div className='sub-name'>{user.username}</div>
                                    </div>
                                </div>
                                <div>
                                    <FollowButton hostname={hostname} userAgent={userAgent} />
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        }
    </>;
}

SearchUserList.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
}
