import {useEffect, useState} from 'react';

/**
 * React hook factory for sharing a state between different components.
 * @param initialState the initial state
 * @return the generated hook
 */
export function sharedStateHookFactory(initialState) {
    const subscriptions = [];
    let lastState = initialState;

    return () => {
        const [state, setState] = useState(lastState);

        useEffect(() => {
            subscriptions.push(setState);

            return () => {
                const index = subscriptions.indexOf(setState);
                if (index !== -1) {
                    subscriptions.splice(index, 1);
                }
            };
        }, []);

        return [
            state,
            newState => {
                lastState = newState;
                subscriptions.forEach(callback => callback(newState));
            }
        ];
    };
}
