/**
 * Detects whether the client is an Android browser.
 * @param userAgent the user agent
 * @return {boolean} true if an Android browser is detected
 */
export function isAndroid(userAgent) {
    return /Android/i.test(userAgent);
}

/**
 * Detects whether the client is an iOS browser.
 * @param userAgent the user agent
 * @return {boolean} true if an iOS browser is detected
 */
export function isIOS(userAgent) {
    return /iPhone|iPad|iPod/i.test(userAgent);
}

/**
 * Detects whether the client is a mobile browser.
 * @param userAgent the user agent
 * @return {boolean} true if a mobile browser is detected
 */
export function isMobile(userAgent) {
    return isAndroid(userAgent) || isIOS(userAgent);
}
