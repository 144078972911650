import React from 'react';
import PropTypes from "prop-types";

import './FormCheckBox.css';

/**
 * React Hook Form을 사용하는 checkbox 컴포넌트
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormCheckbox({ name, register, required = true, ...rest }) {
    return (
        <div className='form-checkbox'>
            <input type="checkbox" id={name} {...register(name, {required : required})} {...rest} />
            <label htmlFor={name}></label>
        </div>
    );
}

FormCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    register: PropTypes.func,
    required: PropTypes.bool
};


