import { useQuery } from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

const SEE_USERS = gql`
    query seeUsers($searchQuery: String!) {
        searchUsers: seeUsers(filter:{query: $searchQuery}) {
            id
            nickname
            username
            resizedSmallImage
            is_following
            seller {
                is_certified
            }
        }
    }
`;

/**
 * Search matching users.
 * @param searchQuery the search query
 * @return {*} the list of users
 */
export default function useSearchUser(searchQuery) {
    // search users if a query is present
    const searchPeople = useQuery(
        SEE_USERS,
        {
            fetchPolicy: 'cache-first',
            variables: {
                searchQuery
            },
            skip: !searchQuery || searchQuery.trim().length < 2 // api does not allow single character searches
        }
    );

    return searchQuery && searchPeople
}
