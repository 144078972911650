import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/react-hooks';
import {gql} from 'apollo-boost';

import Loading from '../../../component/loading/Loading';
import {PRODUCT_FRAGMENT} from '../../../component/productPreview/ProductPreview';
import ProductsListItem from '../../../component/productsList/productsListItem/ProductsListItem';
import {isMobile} from '../../../util/navigator';

import './HorizontalProductsList.css';

const PLACEHOLDER_DATA = {
    items: [{
        id: '-1',
        title: '\xa0',
        status: 'selling',
        brand: '\xa0',
        external_url: null,
        price: -1,
        resizedSmallImages: []
    }]
};

const PRODUCT_DETAILS_PRELOAD_FRAGMENT = gql`
    fragment ProductDetailsPreloadFragment on ProductNotMine {
        id
        createdAt
        category
        title
        brand
        price
        status
        external_url
        resizedSmallImages
        is_visible
        size
        condition
        discount_rate
        like_count
    }
`;

// GraphQL queries
const FILTERED_PRODUCTS = gql`
    query seeProductsCached($filter: ProductFilter!, $sort: String!) {
        items: seeProductsCached(filter: $filter, sort: $sort, limit: 20) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
            price
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

const COLLECTION_PRODUCT = gql`
    query GetCollectionProducts($collectionId: Int!) {
        items: getCollectionProducts(collection_id: $collectionId, offset: 0, limit: 20) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
            price
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Horizontal products list component.
 */
export default function HorizontalProductsList({ userAgent, rowClassName, variables, isCollectionProduct = false, isMultiple = false}) {
    const { loading, data } = useQuery(
        isCollectionProduct ? COLLECTION_PRODUCT : FILTERED_PRODUCTS,
        {
            variables,
            fetchPolicy: 'cache-and-network'
        }
    );

    // build the UI elements
    // render invisible placeholder data while loading, to set the container height and guarantee a stable layout
    const items = (data || PLACEHOLDER_DATA).items.map((product, index) => (
        <li className={`HorizontalProductsList-item col ${loading && !data ? 'HorizontalProductsList-item--loading' : ''}`}
            key={index}>
            <ProductsListItem product={product} />
        </li>
    ));

    return (
        <ul className={`HorizontalProductsList list-inline row  ${rowClassName} ${isMobile(userAgent) ? 'HorizontalProductsList--mobile' : ''} ${isMultiple && !isMobile(userAgent) ? `HorizontalProductsList--multiple` : ''}`}>
            {loading && !data && (
                <li className="HorizontalProductsList-loading">
                    <Loading />
                </li>
            )}
            {items}
        </ul>
    );
}
HorizontalProductsList.propTypes = {
    userAgent: PropTypes.string.isRequired,
    rowClassName: PropTypes.string.isRequired,
    variables: PropTypes.object.isRequired,
    isMultiple: PropTypes.bool,
    isCollectionProduct: PropTypes.bool
};
