import React, {useEffect, useState} from "react";

import './FacebookLoginButton.css';
import icLogoFacebook from "./ic_logo_facebook.svg";

/**
 * FacebookLoginButton
 * @returns {JSX.Element}
 * @constructor
 */
export default function FacebookLoginButton() {
    const clientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
    const [redirectUri, setRedirectUri] = useState('');

    useEffect(() => {
        const hostname = window.location.hostname;

        if (hostname === 'fruitsfamily.com') {
            setRedirectUri('https://fruitsfamily.com/auth/facebook/complete');
        } else {
            setRedirectUri('https://staging.fruitsfamily.com/auth/facebook/complete');
        }
    }, []);

    const facebookLoginUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code`;

    const handleClick = () => {
        window.location.href = facebookLoginUrl;
    };

    return <button onClick={handleClick} className='FacebookLoginButton'>
        <img src={icLogoFacebook} alt='facebook-logo' />
        <div>Facebook으로 계속하기</div>
    </button>
}
