import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './Carousel.css';

/**
 * A component for a bootstrap carousel.
 * @param children the children element as the carousel content
 * @param hideControls hide the carousel controls
 * @param hideIndicators hide the carousel indicators
 * @param options bootstrap carousel options
 * @param autoPlay auto play the carousel
 * @return {*}
 * @constructor
 */
export default function Carousel({ children, hideControls, hideIndicators, options, autoPlay = true }) {
    const carouselId = useMemo(
        () => `carousel_${Date.now()}_${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`,
        []
    );
    const [carouselRef, setCarouselRef] = useState(null);
    useEffect(() => {
        if (carouselRef) {
            const element = $(carouselRef);
            element.carousel(options);

            return () => element.carousel('dispose');
        }
    }, [carouselRef, children, options]);

    return (
        <div id={carouselId}
             className="Carousel carousel slide"
             data-interval={autoPlay ? 5000 : false}
             data-ride="carousel"
             ref={setCarouselRef}>
            {!hideIndicators && children.length > 1 && (
                <ol className="carousel-indicators">
                    {children.map((node, index) => (
                        <li data-target={`#${carouselId}`}
                            data-slide-to={index}
                            className={index === 0 ? 'active' : ''}
                            key={index}></li>
                    ))}
                </ol>
            )}
            <div className="carousel-inner">
                {children.map((node, index) => {
                    return (
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`}
                             key={index}>
                            {node}
                        </div>
                    );
                })}
            </div>
            {!hideControls && children.length > 1 && (
                <a className="carousel-control-prev"
                   href={`#${carouselId}`}
                   role="button"
                   data-slide="prev">
                    <span className="carousel-control-prev-icon"
                          aria-hidden="true"></span>
                    <span className="sr-only">이전</span>
                </a>
            )}
            {!hideControls && children.length > 1 && (
                <a className="carousel-control-next"
                   href={`#${carouselId}`}
                   role="button"
                   data-slide="next">
                    <span className="carousel-control-next-icon"
                          aria-hidden="true"></span>
                    <span className="sr-only">다음</span>
                </a>
            )}
        </div>
    );
}
Carousel.propTypes = {
    children: PropTypes.node.isRequired,
    hideControls: PropTypes.bool,
    hideIndicators: PropTypes.bool,
    options: PropTypes.object,
    autoPlay: PropTypes.bool,
};

