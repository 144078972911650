import {gql} from "apollo-boost";
import {useLazyQuery} from "@apollo/react-hooks";
import _ from "lodash";
import {homeUrl} from "../util/url";
import {useEffect} from "react";
import {useAuthStore} from "../store/useAuthStore";
import {useNavigate} from "react-router-dom";

const GET_LOGIN_EMAIL = gql`
  query signinByEmail(
    $email: String!
    $password: String!
  ) {
    signinByEmail(
      email: $email
      password: $password
    ) {
      id
      login_type
      username
      nickname
      access_token
      refresh_token
      nickname
      following_count
      follower_count
      bio
      resizedSmallImage
      resizedBigImage
      email
      phone
      gender
      createdAt
      updatedAt
      seller {
        id
        income
        rating
        sold_count
        fee
        seller_account {
          bank_name
          account_holder_name
          bank_account
        }
      }
    }
  }
`;

export default function useLoginEmail() {
    const {login} = useAuthStore();
    const navigate = useNavigate();
    const [loginEmail, { called, data, loading, error }] = useLazyQuery(
        GET_LOGIN_EMAIL
    );

    useEffect(() => {
        if (called && !_.isEmpty(data)) {
            (async () => {
                login(data.signinByEmail);
                navigate(homeUrl());
            })();
        }
    }, [called, data, loading, error]);

    return [loginEmail, {called, data, loading, error}];
}
