import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

/**
 * Twitter card metadata component for a collection.
 * @param collection a collection for which to generate metadata
 * @param title meta title
 * @return {*}
 * @constructor
 */
export default function CollectionTwitterCard({ collection, title }) {
    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@fruitsfamilyco"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={collection.description}/>
            <meta name="twitter:image" content={collection.resizedBigImage}/>
            <meta name="twitter:image:alt" content={title}/>
        </Helmet>
    );
}
CollectionTwitterCard.propTypes = {
    collection: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        resizedBigImage: PropTypes.string.isRequired
    }).isRequired,
    title: PropTypes.string.isRequired
};
