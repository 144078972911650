import React from 'react';

import IconShield from './assets/icon_shield.svg';
import './SafetyPurchaseInfo.css';

/**
 * 안전 결제 정보 컴포넌트
 * @returns {JSX.Element}
 * @constructor
 */
export default function SafetyPurchaseInfo() {
    return <a href="https://intercom.help/fruits-family-co/articles/3674785"
              rel="noopener noreferrer"
              target="_blank" className='SafetyPurchaseInfo'>
                <div className='SafetyPurchaseInfo-button'>
                    <img alt={'shield'} src={IconShield} />
                    <div className='text'>100% 안전한 ‘안전결제’로 거래하세요</div>
                </div>
    </a>
}
