import React from 'react';
import PropTypes from "prop-types";

import './StatusErrorProduct.css';
import HorizontalProductsList from "../../page/home/horizontalProductsList/HorizontalProductsList";

/**
 * 상품 에러 UI
 * @returns {JSX.Element}
 * @constructor
 */
export default function StatusErrorProduct({userAgent, message = '상품 정보를 가져오지 못했습니다.'}) {
    return (
        <div className='container'>
            <div className='StatusErrorProduct-container'>
                <h1 className='StatusErrorProduct-title'>{message}</h1>
            </div>
            <div>
                <h2 className='StatusErrorProduct-section-title'>인기 상품 구경하기</h2>
                <HorizontalProductsList userAgent={userAgent}
                                        rowClassName="row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5"
                                        isCollectionProduct={true}
                                        isMultiple={true}
                                        variables={{
                                            collectionId: 1
                                        }} />
            </div>
        </div>
    );
}

StatusErrorProduct.propTypes = {
    userAgent: PropTypes.string.isRequired,
    message: PropTypes.string,
}
