import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {sellerUrl, webappURL} from '../../util/url';

/**
 * Open Graph metadata component for a seller.
 * @param seller a seller for which to generate metadata
 * @param title meta title
 * @param description meta description
 * @param hostname
 * @param sellerTab
 * @return {*}
 * @constructor
 */
export default function SellerOpenGraph({ seller, title, description, hostname, sellerTab }) {
    return (
        <Helmet>
            {/* Cf schema at https://ogp.me/#type_profile */}
            <meta property="fb:app_id" content="330702484245822" />
            <meta property="og:site_name" content="FruitsFamily" />
            <meta property="og:type" content="profile" />
            <meta property="og:title" content={title} />
            <meta property="og:description"
                  content={description}
            />
            <meta property="og:url" content={webappURL(hostname) + sellerUrl(seller, sellerTab)} />
            {seller.user.resizedBigImage
                ? <meta property="og:image" content={seller.user.resizedBigImage} />
                : <meta property="og:image" content={`https://${hostname}/logo512.png`} />
            }
            <meta property="profile:username" content={seller.user.username} />
        </Helmet>
    );
}
SellerOpenGraph.propTypes = {
    seller: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        user: PropTypes.shape({
            username: PropTypes.string.isRequired,
            nickname: PropTypes.string.isRequired,
            bio: PropTypes.string,
            resizedBigImage: PropTypes.string
        }).isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
    sellerTab: PropTypes.symbol,
};
