import {gql} from 'apollo-boost';
import {useLazyQuery} from "@apollo/react-hooks";

export const GET_CHECK_VERTIFICATION_CODE = gql`
  query identificateNumberV2($phone: String!, $number: String!) {
    identificateNumberV2(phone: $phone, number: $number)
  }
`;

export default function useCheckVerificationCode() {
    const [checkVerificationCode, {data, error}] = useLazyQuery(GET_CHECK_VERTIFICATION_CODE, {
        fetchPolicy: 'network-only',
    });

    const executeQuery = (phone, number) => {
        checkVerificationCode({ variables: { phone: phone, number: number } });
    };

    return [executeQuery, data, error];
}
