import React from 'react';
import MountedWrapper from '../../component/mountedWrapper/MountedWrapper';
import Purchased from './Purchased';

export default function PurchasedWrapper() {
    return (
        <MountedWrapper>
            <Purchased/>
        </MountedWrapper>
    );
}