import React from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

import {capitalize, seoURIComponent} from '../../util/text';
import {discoverUrl, webappURL} from '../../util/url';
import Error from '../../component/error/Error';
import Loading from '../../component/loading/Loading';
import {PRODUCT_FRAGMENT} from '../../component/productPreview/ProductPreview';
import ProductsList from '../../component/productsList/ProductsList';
import {META_TAG_TITLE} from "../../constants/constants";
import {PRODUCT_DETAILS_PRELOAD_FRAGMENT} from '../product/Product';

import './Discover.css';
import PropTypes from 'prop-types';

export const CATEGORY_ALL = {
    name: '전체'
};
export const DISCOVER_TAB = {
    PRODUCT: Symbol('PRODUCT'),
    SHOP: Symbol('SHOP')
};

// GraphQL queries
export const ALL_CATEGORIES = gql`
    {
        getCategories {
            name
        }
    }
`;
const SEE_PRODUCTS = gql`
    query SeeProducts($filter: ProductFilter!, $offset: Int, $limit: Int, $sort: String) {
        searchProducts(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
            ...ProductFragment
            ...ProductDetailsPreloadFragment
            price
        }
    }
    ${PRODUCT_FRAGMENT}
    ${PRODUCT_DETAILS_PRELOAD_FRAGMENT}
`;

/**
 * Category discover component.
 * @param hostname
 */
export default function Discover({hostname}) {
    const {
        discoverTab: encodedDiscoverTab = seoURIComponent(DISCOVER_TAB.PRODUCT.description), // default parameter value
        categoryName: partiallyEncodedCategoryName = CATEGORY_ALL.name // default parameter value
    } = useParams();
    const discoverTab = Object.values(DISCOVER_TAB)
        .find(symbol => encodedDiscoverTab === seoURIComponent(symbol.description));

    // fix for reacter-router partial decoding of uri component parameters
    let categoryName;
    try {
        categoryName = decodeURIComponent(partiallyEncodedCategoryName)
    } catch (error) {
        categoryName = partiallyEncodedCategoryName;
    }

    // fetch categories
    const { loading, error, data } = useQuery(ALL_CATEGORIES);

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    // add client-side pseudo-categories
    const allCategories = data ?
        [CATEGORY_ALL, ...data.getCategories] :
        null;

    // find the selected category matching with the categoryName route parameter
    let selectedCategory;
    if (allCategories) {
        selectedCategory = allCategories.find(category => categoryName === category.name) || CATEGORY_ALL;
    }

    // build the UI elements
    const categoryItems = allCategories ?
        allCategories.map(category => (
            <li className="Discover-filter-item"
                key={category.name}>
                <NavLink className={`Discover-filter-toggle btn btn-outline-dark font-nhaasgrotesk ${selectedCategory.name === category.name ? 'active' : ''}` }
                         to={discoverUrl(discoverTab, category)} end={true}
                         replace={true}>
                    {category.name}
                </NavLink>
            </li>
        )) :
        null;

    const h1Content = selectedCategory.name !== CATEGORY_ALL.name ? `${selectedCategory.name} | 빈티지 세컨핸드 패션 플랫폼` : '빈티지 세컨핸드 패션 플랫폼';
    return (
        <section className="Discover container">
            <Helmet>
                <title>{selectedCategory.name !== CATEGORY_ALL.name ? capitalize(selectedCategory.name) : '마켓'} | {META_TAG_TITLE}</title>
                <meta name="description"
                      content={selectedCategory.name !== CATEGORY_ALL.name ?
                          `${capitalize(selectedCategory.name)} 카테고리의 빈티지 세컨핸드 상품을 후루츠에서 한번에.` :
                          '유니크한 디자이너 브랜드 제품부터 구하기 힘든 구시즌 아카이브 명작까지 모두 후루츠에서 디깅해 보세요.'
                      } />
                <link rel="canonical" href={webappURL(hostname) + discoverUrl(discoverTab, selectedCategory)} />
                {discoverTab === DISCOVER_TAB.PRODUCT && (
                    <link rel="alternate" type="application/atom+xml" href={`${discoverUrl(discoverTab, selectedCategory)}/feed.atom`} />
                )}
            </Helmet>

            <div className="Discover-products">
                <h1 className="Discover-h1-tag">{h1Content}</h1>

                <ul className="Discover-filter list-unstyled d-flex flex-wrap">
                    {categoryItems}
                </ul>
                <ProductsList
                    itemsProperty="searchProducts" query={SEE_PRODUCTS}
                    variables={{
                        filter: {
                            category: selectedCategory === CATEGORY_ALL ? undefined : selectedCategory.name
                        },
                        sort: 'POPULAR'
                    }} />
            </div>
        </section>
    );
}
Discover.propTypes = {
    hostname: PropTypes.string.isRequired,
}
