import React, {useMemo} from 'react';
import fruitsfamilyLogo from "../appTopBanner/assets/rounded_logo.svg";
import iconStar from "./assets/ic_yellow_star.svg";
import bgQRBorder from "./assets/bg_qr_border.svg";
import googleStoreBadge from "./assets/google_store_download_badge.svg";
import appStoreBadge from "./assets/app_store_download_badge.svg";
import PropTypes from "prop-types";
import {useAppStoreUrl, useGooglePlayUrl} from "../../hook/appUrl";
import qr from "qr-image";
import popupClose from "./assets/popup_close.svg";
import {useAppInstallModal} from "../../store/useAppInstallModal";

/**
 * 앱설치 유도 팝업 PC 버전
 * @param text
 * @returns {Element}
 * @constructor
 */
export default function AppInstallModalPc({ text }) {
    const { closeModal } = useAppInstallModal();
    const appStoreUrl = useAppStoreUrl();
    const googlePlayUrl = useGooglePlayUrl();
    const qrCode = useMemo(
        () => qr.svgObject(
            'https://fruitsfamily.app.link/JU10tJPXV8',
            {
                margin: 0,
                type: 'svg',
            }
        ),
        []
    );

    return <div className='AppInstallModal-Pc-content'>
        <div onClick={closeModal} className="PopupClose">
            <img src={popupClose} alt='popup-close' className="PopupCloseImage" />
        </div>
        <div className='Title'>{text || `앱에서 후루츠를\n제대로 즐겨보세요!`}</div>
        <div className='QRBackground'>
            <img src={bgQRBorder} alt="qr-border" className="QRBorder"/>
            <svg className="QR"
                 viewBox={`0 0 ${qrCode.size} ${qrCode.size}`}
                 shapeRendering="crispEdges">
                <title>QR코드로 앱 설치</title>
                <path d={qrCode.path}/>
            </svg>
        </div>
        <div className="AppstoreContainer">
            <img className="Logo" alt="FruitsFamily™ Logo" src={fruitsfamilyLogo}/>
            <div className="Appstore">
                <div className="Text">App Store 평점</div>
                <div className="StoreRating">
                    <div>4.9</div>
                    <div className="StoreRatingImages">
                        <div>
                            <img src={iconStar} alt="yellow-star" className="StoreRatingStar"/>
                            <img src={iconStar} alt="yellow-star" className="StoreRatingStar"/>
                            <img src={iconStar} alt="yellow-star" className="StoreRatingStar"/>
                            <img src={iconStar} alt="yellow-star" className="StoreRatingStar"/>
                            <img src={iconStar} alt="yellow-star" className="StoreRatingStar"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="Footer">
            <a className="DownLoadLink"
               href={appStoreUrl}
               rel="noopener noreferrer"
               target="_blank">
                <img src={appStoreBadge} alt="App Store에서 다운로드 하기"/>
            </a>
            <a className="DownLoadLink"
               href={googlePlayUrl}
               rel="noopener noreferrer"
               target="_blank">
                <img src={googleStoreBadge} alt="Play Store에서 다운로드 하기"/>
            </a>

        </div>
    </div>
}

AppInstallModalPc.propTypes = {
    text: PropTypes.string
}
