import React from 'react';
import PropTypes from 'prop-types';
import './AppOpenButton.css';
import appLink from "../../util/appLink";
import {useLocation} from "react-router-dom";

export default function AppOpenButton({ hostname, userAgent }) {
    const location = useLocation();
    const appLinkUrl = appLink(hostname, location.pathname + location.search + location.hash, userAgent);

    return (
        <a className={'App-open-button-mobile'} href={appLinkUrl}>
            <div>앱 열기</div>
        </a>
    );
}

AppOpenButton.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired,
};