import React from 'react';
import PropTypes from 'prop-types';

import './FollowButton.scss';
import {useLocation} from "react-router-dom";
import appLink from "../../util/appLink";

export default function FollowButton({hostname, userAgent}) {
    const location = useLocation();
    const applinkUrl = appLink(hostname, location.pathname + location.search + location.hash, userAgent);

    const handleClick = (event) => {
        event.stopPropagation();
    };

    return (
        <a href={applinkUrl} className='FollowButton' onClick={handleClick}>
            <div className='FollowButton-text'>팔로우</div>
        </a>
    );
}

FollowButton.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
