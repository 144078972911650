import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {gql} from 'apollo-boost';

import SoldRibbon from '../soldRibbon/SoldRibbon';
import {productUrl} from '../../util/url';

import './ProductPreview.css';

// GraphQL queries
export const PRODUCT_FRAGMENT = gql`
    fragment ProductFragment on ProductNotMine {
        id
        title
        brand
        status
        external_url
        resizedSmallImages
    }
`;

export default function ProductPreview({ product }) {
    // build the UI elements
    return (
        <Link className="ProductPreview"
              to={productUrl(product)}>
            <img className="ProductPreview-image"
                 loading="lazy"
                 alt={product.title}
                 src={product.resizedSmallImages[0]} />
            <div className="ProductPreview-sold">
                <SoldRibbon product={product} />
            </div>
        </Link>
    );
}
ProductPreview.propTypes = {
    product: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        status: PropTypes.oneOf(['selling', 'sold']).isRequired,
        resizedSmallImages: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired
};
