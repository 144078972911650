import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";

import IconAdd from './assets/icon_add.svg';
import {useDeliveryAddress} from "../../store/useDeliveryAddress";

import './CheckoutShipping.css';


/**
 * Subcomponent for the shipping address.
 * @param productId
 * @param deliveryAddress
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckoutShipping({productId, deliveryAddress}) {
    const navigate = useNavigate();
    const {setDeliveryAddress, deliveryNote} = useDeliveryAddress();

    const handleRegisterShippingInfo = () => {
        navigate(`/checkout/shipping/${productId}`);
    }

    useEffect(() => {
        setDeliveryAddress(deliveryAddress);
    }, [deliveryAddress]);

    return <div className='CheckoutShipping'>
        <div className='CheckoutShipping-header'>
            <div className='title'>배송지</div>
            {deliveryAddress !== null && <div className='CheckoutShipping-change' onClick={handleRegisterShippingInfo}>주소변경</div>}
        </div>

        {deliveryAddress === null ?
            <div className='CheckoutShipping-add' onClick={handleRegisterShippingInfo}>
                <img alt={'add'} src={IconAdd}  />
                <div className='text'>배송지 추가</div>
            </div>
            : <div className='CheckoutShipping-info-container'>
                <div className='column'>
                    받는분
                </div>
                <div>
                    <div>{deliveryAddress.recipient_name}</div>
                    <div>({deliveryAddress.postal_code}) {deliveryAddress.address1} {deliveryAddress.address2}</div>
                    <div>{deliveryAddress.recipient_phone}</div>
                    <div>{deliveryNote}</div>
                </div>
            </div>}
    </div>;
}

CheckoutShipping.propTypes = {
    productId: PropTypes.string.isRequired,
    deliveryAddress: PropTypes.object,
}
