import React from 'react';
import {Toaster} from "react-hot-toast";


/**
 * 공통 Toast
 */
export default function CustomToast() {
    return <Toaster
        position="top-center"
        duration={3000}
        containerStyle={{
            marginTop: '4.5rem'
        }}
        toastOptions={{
            style: {
                width: '21.25rem',
                padding: '1.313rem 0',
                borderRadius: '0.625rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                letterSpacing: '-0.025rem',
                background: '#000000',
                color: '#FFFFFF',
            },
        }}
    />
}
