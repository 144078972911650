import React from 'react';
import PropTypes from 'prop-types';

import './Rating.css';

const MAX_RATING = 5;

/**
 * Seller like tab component.
 */
export default function Rating({ rating }) {
    return (
        <span className={`Rating ${rating === null ? 'Rating--unrated' : ''}`}>
            {'★'.repeat(Math.round(rating))}{'☆'.repeat(MAX_RATING - Math.round(rating))}
        </span>
    );
}
Rating.propTypes = {
    rating: PropTypes.number
};

