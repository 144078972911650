import React from 'react';
import PropTypes from 'prop-types';
import {useSearchModalStore} from "../../../store/useSearchModalStore";
import iconClose from '../assets/icon_close.svg';
import SearchInputContainer from "../searchInputContainer/SearchInputContainer";
import SearchKeywordDisplay from "../searchKeywordDisplay/SearchKeywordDisplay";
import SearchBrandList from "../searchBrandList/SearchBrandList";
import SearchUserList from "../searchUserList/SearchUserList";

import './SearchModal.scss';
import '../../../component/bootstrap/modal/Modal.css';

/**
 * Search modal
 * @param hostname
 * @param userAgent
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function SearchModal({hostname, userAgent}) {
    const { isOpen, closeModal } = useSearchModalStore();

    if (!isOpen) {
        document.body.style = `overflow: auto`
        document.body.style.position = '';
        return null;
    } else {
        document.body.style= `overflow: hidden`;
        document.body.style.position = 'fixed';
    }

    return (
        <dialog className="Modal modal fade show"
                tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen SearchModalDialog"
                 role="document">
                <div className="Modal-content modal-content">
                    <div className='Modal-body modal-body SearchModalDialog-body'>
                        <div className='SearchModalDialog-content'>
                            <div className='header'>
                                <div className='search'>
                                    <SearchInputContainer />
                                </div>
                                <div onClick={closeModal} className='closeContainer'>
                                    <img alt={'close'} src={iconClose} />
                                </div>
                            </div>

                            <div>
                                <div>
                                    <SearchKeywordDisplay />
                                </div>
                                <div>
                                    <SearchBrandList hostname={hostname} userAgent={userAgent} />
                                </div>
                                <div>
                                    <SearchUserList hostname={hostname} userAgent={userAgent} />
                                </div>
                            </div>
                            <div className='SearchModalDialog-footer' />
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    );
}

SearchModal.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
