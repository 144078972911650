import React from 'react';
import MountedWrapper from '../../component/mountedWrapper/MountedWrapper';
import Checkout from "./Checkout";
import PropTypes from 'prop-types';

export default function CheckoutWrapper({hostname}) {
    return (
        <MountedWrapper>
            <Checkout hostname={hostname}/>
        </MountedWrapper>
    );
}
CheckoutWrapper.propTypes = {
    hostname: PropTypes.string.isRequired,
}
