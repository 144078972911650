import React from 'react';
import {Helmet} from 'react-helmet';

import OrderList from '../../component/orderList/OrderList';
import './Purchased.css';

/**
 * 구매내역 페이지
 * @returns {JSX.Element}
 */
export default function Purchased() {
    return (
        <section className="container">
            <Helmet>
                <title>구매 내역 | MY | 후루츠패밀리</title>
                <meta name="description" content={'후루츠패밀리에서 구매한 내역입니다.'} />
            </Helmet>
            <h1 className="Purchased-title">구매 내역</h1>
            <OrderList/>
        </section>
    );
}